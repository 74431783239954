import React from "react";
import MarketButtons from "./MarketButtons/MarketButtons";

import styles from "./StepTwoStyle.module.css";

const StepTwo = ({ dataComponent }) => {
  return (
    <div className={styles.container}>
      <p className={styles.stepNumber}>{dataComponent.number}</p>
      <p className={styles.stepHeader}>
        <span className={styles.stepHeaderMedia}>
          {dataComponent.headerText}
        </span>
      </p>
      <span className={styles.stepInfoText}>{dataComponent.infoText}</span>
      <div className={styles.showHideButtons}>
        <MarketButtons />
      </div>
      <div className={styles.qrSection}>
        <img
          src="/img/qr-frame-ty.png"
          alt="QR-memoryOS"
          className={styles.qrImg}
        />
        <span className={styles.qrText}>{dataComponent.scanQRText}</span>
      </div>
      <img
        src="/img/frame-step-two-ty.png"
        alt="img-step-one"
        className={styles.stepImg}
      />
    </div>
  );
};

export default StepTwo;
