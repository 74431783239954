import React, { useRef } from "react";
import { useResizeContainer } from "../../../../hooks/resizeContainer";
// import useUniqueComponentIds from "../../../../hooks/useUniqueComponentIds";

import YouTubePlayer from "../../../templates/YoutubePlayer/YoutubePlayer";

import { Step, Column, Title, Button } from "../../styles";
import styles from "./StaticStepStyles.module.css";

const StaticStep = React.memo(({ id, step, handleNextStep }) => {
  const staticStepRef = useRef(null);

  // const ids = useUniqueComponentIds("StaticStep", id, step);

  const { widthCont, heightCont, radiusCont } =
    useResizeContainer(staticStepRef);

  return (
    <Step>
      {step.image || step.video ? (
        <>
          <Column>
            {step.image && (
              <div className={styles.imageContainer}>
                <img
                  className={styles.image}
                  src={step.image}
                  alt={step.title}
                />
              </div>
            )}

            {step.video && (
              <div className={styles.videoPlayer} ref={staticStepRef}>
                <YouTubePlayer
                  videoId={step.youtube_video_id}
                  width={widthCont}
                  height={heightCont}
                  borderRadius={radiusCont}
                />
              </div>
            )}
          </Column>
          <Column>
            {/* <Title id={ids.forElement("title")}>{step.title}</Title> */}
            <Title id={step.title_id}>{step.title}</Title>

            {step.copy.map((copy, index) => (
              <span
                key={`${id}-${index}`}
                // id={ids.forElement(`copy_text${index}`)}
                id={`${step.sub_title_id}${index}`}
                className={styles.copyText}
              >
                {copy}
              </span>
            ))}

            <Button
              $isActive={true}
              onClick={() => handleNextStep({ id, type: "static" })}
            >
              Continue
            </Button>
          </Column>
        </>
      ) : (
        <div className={styles.noImgStyle}>
          <Column>
            {/* <Title id={ids.forElement("title-2")}>{step.title}</Title> */}
            <Title id={step.title_id}>{step.title}</Title>
            {step.copy.map((copy, index) => (
              <span
                key={`${id}-${index}`}
                // id={ids.forElement(`copy_text_2${index}`)}
                id={`${step.sub_title_id}${index}`}
                className={styles.copyText}
              >
                {copy}
              </span>
            ))}
            <Button
              $isActive={true}
              onClick={() => handleNextStep({ id, type: "static" })}
            >
              Continue
            </Button>
          </Column>
        </div>
      )}
    </Step>
  );
});

export default StaticStep;
