import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import Loader from "../pages/templates/Loader/Loader";
import LZUTF8 from "lzutf8";
import { useConsoleLog } from "../hooks/consoleLog";

const UrlContext = createContext();

export const useUrlContext = () => {
  return useContext(UrlContext);
};

export const UrlProvider = ({ children }) => {
  const logger = useConsoleLog();

  const [queryParams, setQueryParams] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);

  const getCurrentPath = async () => {
    return window.location.href;
  };

  useEffect(() => {
    const initializeContext = async () => {
      const storedParams =
        JSON.parse(localStorage.getItem("MemoryOSQueryParams")) || {};
      const params = getQueryParameters();
      let updatedParams = { ...params };

      if (Object.keys(params).length > 0) {
        setQueryParams(updatedParams);
        localStorage.setItem(
          "MemoryOSQueryParams",
          JSON.stringify(updatedParams)
        );
      } else if (Object.keys(storedParams).length > 0) {
        setQueryParams(storedParams);
      }

      setIsInitialized(true);
    };

    initializeContext();
  }, []);

  const getQueryParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dataUrl = {};

    for (const [key, value] of urlParams.entries()) {
      dataUrl[key] = value;
    }

    return dataUrl;
  };

  const [defaultPagePath, setDefaultPagePath] = useState(null);
  const [applyDiscountPage, setApplyDiscountPage] = useState(null);
  const [holidayPromoPath, setHolidayPromoPath] = useState(null);
  const [holidayOldPromoPath, setHolidayOldPromoPath] = useState(null);
  const [
    holidayOldPromoPathDuplicateThree,
    setHolidayOldPromoPathDuplicateThree,
  ] = useState(null);
  const [holidayPromoThreePath, setHolidayPromoThreePath] = useState(null);
  const [duplicatePlanPagePath, setDuplicatePlanPagePath] = useState(null);
  const [duplicatePlanPagePathABtest, setDuplicatePlanPagePathABtest] =
    useState(null);

  const path = window.location.pathname;

  useEffect(() => {
    if (path) {
      // const defaultPath = /\/new(\/|$|\?|#)/.test(path);
      const defaultPath = /^\/($|\?)|(\/new)(\/|$|\?|#)/.test(path);

      const hasDiscountPath = /\/apply-discount(\/|$|\?|#)/.test(path);

      const hasHolidayPromoPath = /\/hp(\/|$|\?|#)/.test(path);

      const hasHolidayPromoOldPath = /\/hp-23(\/|$|\?|#)/.test(path);

      const hasHolidayPromoOldPathDuplicateThree = /\/hp-23d3(\/|$|\?|#)/.test(
        path
      );

      const hasHolidayPromoThreePath = /\/hp-3(\/|$|\?|#)/.test(path);

      const hasDuplicatePlanPagePath = /\/new-h(\/|$|\?|#)/.test(path);

      const hasDuplicatePlanPagePathABtest = /\/new-t(\/|$|\?|#)/.test(path);

      if (hasDiscountPath) {
        localStorage.setItem("MemoryOSDownSale", false);
      }

      setDefaultPagePath(defaultPath);
      setApplyDiscountPage(hasDiscountPath);
      setHolidayPromoPath(hasHolidayPromoPath);
      setHolidayOldPromoPath(hasHolidayPromoOldPath);
      setHolidayOldPromoPathDuplicateThree(
        hasHolidayPromoOldPathDuplicateThree
      );
      setHolidayPromoThreePath(hasHolidayPromoThreePath);
      setDuplicatePlanPagePath(hasDuplicatePlanPagePath);
      setDuplicatePlanPagePathABtest(hasDuplicatePlanPagePathABtest);
    }
  }, [path]);

  const [decompressedData, setDecompressedData] = useState(null);

  useEffect(() => {
    try {
      const raw = window.location.search.split("payload=")[1];
      if (!raw) {
        logger.warn("Payload not found in URL");
        return;
      }

      const payload = raw.includes("&") ? raw.split("&")[0] : raw;

      const decompressed = LZUTF8.decompress(payload, {
        inputEncoding: "Base64",
        outputEncoding: "String",
      });

      logger.info("Decompressed:", decompressed);

      if (!decompressed.includes("~")) {
        logger.warn("Invalid decompressed data format - missing separator");
        return;
      }

      const [userName = "", userEmail = ""] = decompressed.split("~");

      const parsedData = {
        userName: userName?.trim() || "",
        userEmail: userEmail?.trim() || "",
      };

      setDecompressedData(parsedData);
    } catch (error) {
      logger.critical("Decompression error:", error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (decompressedData) {
      const checkLocaluserParams = JSON.parse(
        localStorage.getItem("MemoryOSUserData")
      );
      if (!checkLocaluserParams) {
        localStorage.setItem(
          "MemoryOSUserData",
          JSON.stringify(decompressedData)
        );
        logger.warnInfo("setted user data from memoryos.com");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decompressedData]);

  const validHolidayPathsCheck = useMemo(() => {
    return (
      holidayPromoPath ||
      holidayOldPromoPath ||
      holidayOldPromoPathDuplicateThree ||
      holidayPromoThreePath
    );
  }, [
    holidayPromoPath,
    holidayOldPromoPath,
    holidayOldPromoPathDuplicateThree,
    holidayPromoThreePath,
  ]);

  const contextValue = {
    queryParams,
    getCurrentPath,
    defaultPagePath,
    applyDiscountPage,
    holidayPromoPath,
    holidayOldPromoPath,
    holidayOldPromoPathDuplicateThree,
    holidayPromoThreePath,
    duplicatePlanPagePath,
    duplicatePlanPagePathABtest,
    validHolidayPathsCheck,
  };

  if (!isInitialized) {
    return <Loader />;
  }

  return (
    <UrlContext.Provider value={contextValue}>{children}</UrlContext.Provider>
  );
};
