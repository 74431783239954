import React, { useState } from "react";

// import useUniqueComponentIds from "../../../../hooks/useUniqueComponentIds";

import { useEvent } from "../../../../context/EventContext";
import { useUserData } from "../../../../context/UserData/UserDataContext";

import VideoPlayer from "../../../templates/VideoPlayer/VideoPlayer";

import { Step, Column, Title, Image, Copy, Input, Button } from "../../styles";

const FormStep = React.memo(({ id, step, handleNextStep }) => {
  // const ids = useUniqueComponentIds("FormStep", id, step);

  const { analyticsEvent, setSnaptrUserData } = useEvent();

  const { setStateUserName } = useUserData();

  const [formValues, setFormValues] = useState({});

  const [userCurrentName, setUserName] = useState();

  const isFirstNameStep = !!step.inputs.find(
    (input) => input.name === "firstName"
  );
  const handleInputChange = (prop) => (event) => {
    setFormValues({ [prop]: event.target.value });
    if (isFirstNameStep) {
      localStorage.setItem("MemoryOSUserName", event.target.value);
      setUserName(event.target.value);
    }
  };
  const handleSubmit = (e) => {
    const storedUserData =
      JSON.parse(localStorage.getItem("MemoryOSUserData")) || {};
    storedUserData.userName = userCurrentName;
    setSnaptrUserData(storedUserData);
    setStateUserName(userCurrentName);
    localStorage.setItem("MemoryOSUserData", JSON.stringify(storedUserData));

    e.preventDefault();

    analyticsEvent("nameFormSubmit", userCurrentName);

    handleNextStep({ id, formValues, type: "form" });
  };

  return (
    <Step>
      <Column>
        {/* <Title id={ids.forElement("title")}>{step.title}</Title> */}
        <Title id={step.title_id}>{step.title}</Title>
        <Image>
          {/* <img src={step.image} alt="" /> */}
          <img src={step.image} alt="" />
        </Image>
        {step.video ? (
          <div>
            <VideoPlayer
              videoId={step.video}
              thumbnail={step.thumbnail || ""}
            />
          </div>
        ) : null}
        {step.copy
          ? step.copy.map((copy, index) => (
              <Copy key={`${id}-${index}`}>{copy}</Copy>
            ))
          : null}
      </Column>
      <Column>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {step.inputs.map((input, index) => (
            <Input
              key={`input-${id}-${index}`}
              type={input.type}
              placeholder={input.placeholder}
              name={input.name}
              required
              onChange={(event) => handleInputChange(input.name)(event)}
              autoFocus={input.type === "email" || input.type === "firstName"}
            />
          ))}
          <Button $isActive={true}>Continue</Button>
        </form>
      </Column>
    </Step>
  );
});

export default FormStep;
