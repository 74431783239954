import React, { useState, useEffect, useRef } from "react";
// import useUniqueComponentIds from "../../../../hooks/useUniqueComponentIds";

import { useResizeContainer } from "../../../../hooks/resizeContainer";

import YouTubePlayer from "../../../templates/YoutubePlayer/YoutubePlayer";

import {
  Step,
  Column,
  Title,
  Image,
  AnswersMulti,
  Answer,
  Checkbox,
  Button,
} from "../../styles";

import styles from "./MultipleStepStyles.module.css";

const MultipleStep = React.memo(({ id, step, handleNextStep, userData }) => {
  const multipleStepRef = useRef(null);

  // const ids = useUniqueComponentIds("MultipleStep", id, step);

  const { widthCont, heightCont, radiusCont } =
    useResizeContainer(multipleStepRef);

  const currentName =
    userData.find(
      (elem) => elem.formValues && elem.formValues.hasOwnProperty("firstName")
    )?.formValues?.firstName || "";

  const getSummaryTitle = (title) => {
    const modifiedTitle = title.replace(/\{showName\}/g, `${currentName}`);
    return modifiedTitle;
  };
  const [isActiveButton, setActiveButton] = useState(false);
  const [answers, setAnswers] = useState(
    step.answers.map((item) => ({ copy: item, selected: false }))
  );

  useEffect(() => {
    const activeAnswers = answers.filter(
      (item) => item.selected === true
    ).length;
    setActiveButton(!!(activeAnswers > 0));
  }, [answers]);

  useEffect(() => {
    setAnswers(step.answers.map((item) => ({ copy: item, selected: false })));
  }, [step]);

  const handleAnswerSelection = (index) => {
    setAnswers(
      answers.map((item, idx) => ({
        copy: item.copy,
        selected: idx === index ? !item.selected : item.selected,
      }))
    );
  };

  return (
    <Step key={id}>
      <Column>
        {/* <Title id={ids.forElement("title")}> */}
        <Title id={step.title_id}>
          {currentName ? getSummaryTitle(step.title) : step.title}
        </Title>
        {step.image && (
          <Image>
            {/* <img src={step.image} alt="" /> */}
            <img src={step.image} alt="" />
          </Image>
        )}
        {step.video && (
          <div className={styles.videoPlayer} ref={multipleStepRef}>
            <YouTubePlayer
              videoId={step.youtube_video_id}
              width={widthCont}
              height={heightCont}
              borderRadius={radiusCont}
            />
          </div>
        )}
      </Column>
      <Column>
        <AnswersMulti>
          {answers?.map((answer, index) => (
            <Answer
              key={`${id}-${index}`}
              onClick={() => handleAnswerSelection(index)}
              $isSelected={answer.selected}
            >
              {answer.copy}
              <Checkbox $isSelected={answer.selected} />
            </Answer>
          ))}

          <Button
            $isActive={isActiveButton}
            onClick={() =>
              isActiveButton &&
              handleNextStep({ id, answers, type: "multiple" })
            }
          >
            Continue
          </Button>
        </AnswersMulti>
      </Column>
    </Step>
  );
});

export default MultipleStep;
