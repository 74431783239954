import React from "react";

import styles from "./ErrorHandlerStyle.module.css";
import { usePageConfig } from "../../context/PageConfigContext";

const ErrorHandler = () => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("ErrorHandler");

  return (
    <div className={styles.errContainer}>
      <span className={styles.errText}>{data.errorText}</span>
    </div>
  );
};

export default ErrorHandler;
