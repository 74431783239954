import React, { useEffect, useState, useMemo, useRef } from "react";
import { usePageConfig } from "../../../context/PageConfigContext";

import { useScroll } from "../../../context/ScrollContext";
import { usePageContent } from "../../../context/PageContext";

import TopComponentVariantB from "./TopComponentVariantB/TopComponentVariantB";
import Header from "./Header/Header";
import StepsContainer from "../shared/StepsContainer/StepsContainer";
import BottomComponent from "./BottomComponent/BottomComponent";
import FooterContainer from "./FooterContainer/FooterContainer";

import styles from "./HPThankYouStyle.module.css";
import Loader from "../../templates/Loader/Loader";

const HPThankYou = () => {
  const { findDataComponent } = usePageConfig();
  const { calculateRenewalDate, userCancelDiscountHP } = usePageContent();

  const [userDiscountStatus, setuserDiscountStatus] = useState(null);
  const getLocalStorageStatus = JSON.parse(
    localStorage.getItem("MemorryOSdiscountHP")
  );
  useEffect(() => {
    setuserDiscountStatus(
      getLocalStorageStatus !== null
        ? getLocalStorageStatus
        : userCancelDiscountHP
    );
  }, [getLocalStorageStatus, userCancelDiscountHP]);

  const [userName, setUserName] = useState("");
  const [planData] = useState(
    JSON.parse(localStorage.getItem("MemoryOSPlanData"))
  );
  const [showHideParagraph, setShowHideParagraph] = useState(true);

  const dataComponent = findDataComponent("RedesignThankYou");
  const [currentPath] = useState(window.location.href);

  const isNewPath = useMemo(() => {
    const targetPath = "/new/thankyou";

    return !currentPath.includes(targetPath);
  }, [currentPath]);

  const thankyouPagePath =
    currentPath.includes("/new/thankyou") ||
    currentPath.includes("/hp/thankyou") ||
    currentPath.includes("/hp-23/thankyou") ||
    currentPath.includes("/hp-23d/thankyou") ||
    currentPath.includes("/hp-23d2/thankyou") ||
    currentPath.includes("/hp-23d3/thankyou") ||
    currentPath.includes("/hp-3/thankyou");

  useEffect(() => {
    setShowHideParagraph(isNewPath);
  }, [isNewPath]);

  useEffect(() => {
    if (thankyouPagePath) {
      const storedData = localStorage.getItem("MemoryOSUserName");
      const userNameData = JSON.parse(localStorage.getItem("MemoryOSUserData"));
      setUserName(
        storedData !== null
          ? storedData
          : userNameData !== null
          ? userNameData.userName
          : ""
      );
      calculateRenewalDate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thankyouPagePath]);

  const [isLoading, setIsLoading] = useState(true);
  const { scrollRefTop, scrollToTopComponent } = useScroll();
  const PageContainerRef = useRef(null);

  useEffect(() => {
    scrollRefTop.current = PageContainerRef.current;
  }, [scrollRefTop]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      scrollToTopComponent();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }, 100);
  }, [scrollToTopComponent]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles.container}>
      <div ref={PageContainerRef} />
      <Header />

      <TopComponentVariantB
        userName={userName}
        dataComponent={dataComponent}
        planText={planData}
        userDiscountStatus={userDiscountStatus}
      />
      {!userDiscountStatus && (
        <StepsContainer
          dataComponent={dataComponent}
          showHideParagraph={showHideParagraph}
        />
      )}
      <BottomComponent dataComponent={dataComponent.bottomSection} />
      <FooterContainer />
    </div>
  );
};

export default HPThankYou;
