import React from "react";
import styles from "./ButtonComponentStyle.module.css";

const ButtonComponent = ({ text, callback }) => {
  const handleClick = () => {
    if (callback) {
      callback();
    }
  };

  return (
    <button className={styles.button} onClick={handleClick}>
      <span className={styles.text}>{text}</span>
    </button>
  );
};

export default ButtonComponent;
