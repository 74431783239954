import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useNavigationPath } from "../../../hooks/navigationPath";
import { preserveUrlParams } from "../../../utils/urlParams";

import { usePageConfig } from "../../../context/PageConfigContext";
import { usePaymentBlock } from "../../../context/PaymentBlockContext";
import { useEvent } from "../../../context/EventContext";
import { useUserData } from "../../../context/UserData/UserDataContext";
import { usePaymentForm } from "../../../context/PaymentFormContext";
import { useUrlContext } from "../../../context/urlContext";

import ReactivateEmailInput from "./ReactivateEmailInput/ReactivateEmailInput";
import LogoHP from "../../templates/LogoHP/LogoHP";

import styles from "./HasActiveSubscModal.module.css";

const HasActiveSubscModal = () => {
  const { analyticsEvent, setSnaptrUserData } = useEvent();
  const { findDataComponent } = usePageConfig();

  const { setStartActiveSubscForm, loadingForm } = usePaymentBlock();
  const { setErrPopupData } = usePaymentForm();
  const { applyDiscountPage, validHolidayPathsCheck } = useUrlContext();
  const { setStateUserEmail } = useUserData();

  const navigate = useNavigate();
  const previosPath = useNavigationPath("plan");
  const previosApplyDiscountPath = useNavigationPath("applyDiscount");

  const shortenPath = (path) => {
    const parts = path.split("/").filter((part) => part !== "");
    const uniqueParts = [...new Set(parts)];
    return "/" + uniqueParts.join("/");
  };

  const shortApplyDiscountPath = shortenPath(previosApplyDiscountPath);

  const data = findDataComponent("HasActiveSubscModal");

  const [newUserEmail, updatenewUserEmai] = useState({});
  const [validUserData, setValidUserData] = useState(false);

  const handleSubmit = async () => {
    const userData = JSON.parse(localStorage.getItem("MemoryOSUserData"));

    if (userData !== null && userData.userEmail) {
      const updatedUserNoQuizData = {
        ...userData,
        userEmail: newUserEmail.userEmail,
      };
      localStorage.setItem(
        "MemoryOSUserData",
        JSON.stringify(updatedUserNoQuizData)
      );
      setSnaptrUserData(updatedUserNoQuizData);
      await setStateUserEmail(newUserEmail.userEmail);
    }

    analyticsEvent("conversion");

    setStartActiveSubscForm(false);
    setErrPopupData({ visible: false });
    loadingForm("StartPaymentForm");
    window.location.reload();
  };

  const closePopup = () => {
    setErrPopupData({ visible: false });
    if (!validHolidayPathsCheck && applyDiscountPage) {
      navigate(preserveUrlParams(shortApplyDiscountPath));
    } else if (!validHolidayPathsCheck) {
      navigate(preserveUrlParams(previosPath));
    }
    setStartActiveSubscForm(false);
  };

  return (
    <div className={styles.HasActiveSubscModal}>
      <button className={styles.closeButton} onClick={() => closePopup()}>
        <img
          src="../img/SpecialOfferCloseImg.png"
          alt="CloseImg"
          className={styles.closeImg}
        />
      </button>
      <div className={styles.HasActiveSubscModalContainer}>
        <LogoHP />
        <div className={styles.HasActiveSubscModalText}>{data.text}</div>
        <ReactivateEmailInput
          data={data}
          updatenewUserEmai={updatenewUserEmai}
          setValidUserData={setValidUserData}
        />
        <button
          className={
            validUserData === true
              ? styles.HasActiveSubscModalButton
              : styles.HasActiveSubscModalButtonDisable
          }
          onClick={() => {
            if (validUserData) {
              handleSubmit();
            }
          }}
        >
          {data.buttonText}
        </button>
      </div>
    </div>
  );
};

export default HasActiveSubscModal;
