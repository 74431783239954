import React, { useMemo } from "react";
import { jwtDecode } from "jwt-decode";

import { useCleanPath } from "../../../../../../hooks/useCleanPath";

import { useRemoteConfig } from "../../../../../../context/RemoteConfigContext";
import { useUserData } from "../../../../../../context/UserData/UserDataContext";

import { LoginSocialApple, LoginSocialGoogle } from "reactjs-social-login";

import Loader from "../../../../../templates/Loader/Loader";

import styles from "./AuthButtonsStyles.module.css";
import { usePageContent } from "../../../../../../context/PageContext";

const AuthButtons = ({
  dataComponent,
  enableAppleLogin,
  enableGoogleLogin,
  enableFacebook,
}) => {
  const { appData } = useRemoteConfig();
  const { initUserEmailLinking } = useUserData();
  const cleanCurrentPath = useCleanPath();

  const {isWebView} = usePageContent();
  

  const redirectUri = useMemo(() => {
    if (!cleanCurrentPath) {
      return "https://staging.start.memoryos.com/new/thankyou";
    }
    return cleanCurrentPath;
  }, [cleanCurrentPath]);

  const handleAppleLogin = (response) => {
    try {
      const { provider, data } = response;

      const decodedToken = jwtDecode(data.authorization.id_token);

      const enrichedData = {
        ...data,
        email: decodedToken.email,
        email_verified: decodedToken.email_verified,
        user_id: decodedToken.sub,
      };

      initUserEmailLinking(provider, enrichedData);
    } catch (error) {
      console.error("Error processing Apple login:", error);
    }
  };

  if (!redirectUri) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {enableAppleLogin && (
        <LoginSocialApple
          client_id={appData.REACT_APP_APPLE_CLIENT_ID}
          scope={"name email"}
          redirect_uri={redirectUri}
          onResolve={handleAppleLogin}
          onReject={(error) => {
            console.error("Apple login error:", error);
          }}
          // className={styles.element}
        >
          <div className={`${styles.button} ${styles.apple}`}>
            <img
              src="/img/Apple-ty.png"
              alt="apple_id"
              className={styles.btnIcons}
            />
            <span className={styles.whiteText}>{dataComponent.apple}</span>
          </div>
        </LoginSocialApple>
      )}
      {enableGoogleLogin && !isWebView && (
  <LoginSocialGoogle
    client_id={appData.REACT_APP_GOOGLE_CLIENT_ID}
    scope="openid profile email"
    redirect_uri={redirectUri}
    onResolve={(response) => {
      const { provider, data } = response;
      initUserEmailLinking(provider, data);
    }}
    onReject={(error) => {
      console.log(error);
    }}
    // className={styles.element}
  >
    <div className={`${styles.button} ${styles.google}`}>
      <img
        src="/img/Google-ty.png"
        alt="google_id"
        className={styles.btnIcons}
      />
      <span className={styles.blackText}>{dataComponent.google}</span>
    </div>
  </LoginSocialGoogle>
)}
    </div>
  );
};

export default AuthButtons;
