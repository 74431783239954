import React, { createContext, useContext, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";

import { useUrlContext } from "./urlContext";
import { useGrowthbook } from "./GrowthBookContext";

import { useConsoleLog } from "../hooks/consoleLog";

import { useEnvContext } from "./EnvironmentContext";
import { useDevMode } from "./DevMode";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import {
  getRemoteConfig,
  fetchAndActivate,
  getAll,
} from "firebase/remote-config";
// import Loader from "../pages/templates/Loader/Loader";

const RemoteConfigContext = createContext();
export const useRemoteConfig = () => useContext(RemoteConfigContext);

export const FirebaseConfigProvider = ({ children }) => {
  const { isProduction } = useEnvContext();
  const {
    defaultPagePath,
    applyDiscountPage,
    holidayPromoPath,
    holidayOldPromoPath,
    holidayOldPromoPathDuplicateThree,
    holidayPromoThreePath,
    duplicatePlanPagePath,
    duplicatePlanPagePathABtest,
  } = useUrlContext();
  const { devModeVariant } = useDevMode();

  const [appData, setAppData] = useState({});
  const [quizData, setQuiz] = useState({});
  const [feedbackData, setFeedbackData] = useState({});

  const [componentsData, setComponentsData] = useState({});
  const [waitlistPageData, setWaitListPageData] = useState({});
  const [reservePageData, setReservePageData] = useState({});
  const [peopleOnWaitlist, setPeopleOnWaitlist] = useState("");
  const [newPageReviews, setNewPageReviews] = useState([]);
  const [newPageVideoReviews, setVideoReviews] = useState([]);
  const [fbEvent, setFbEvent] = useState(false);
  const [paymentButtons, setPaymentButtons] = useState({});
  const [optionTextData, setOptionTextData] = useState({});
  const [apiGeoUrl, setApiGeoUrl] = useState("");

  const [redirectUrl, setRedirectUrl] = useState("");
  const [emailProviders, setEmailProviders] = useState({});

  const [abTestCampaignName, setABtestCampaignName] = useState(null);
  const [abTestStartCampaignDate, setABtestStartCampaignDate] = useState(null);

  const [emailsToDisableAnalyticsEvent, setEmailsToDisableAnalyticsEvent] =
    useState([]);

  const [QAtimerTime, setQAtimerTime] = useState(0);

  const [isConfigReady, setIsConfigReady] = useState(false);

  const firebaseConfig = {
    apiKey: "AIzaSyDDEVWxCxP8u_hvobFFusUHuOgkDuGCk6s",
    authDomain: "memoryos-web-funnel.firebaseapp.com",
    projectId: "memoryos-web-funnel",
    storageBucket: "memoryos-web-funnel.appspot.com",
    messagingSenderId: "1056744550522",
    appId: "1:1056744550522:web:f51c7114e8af84f7760bc3",
    measurementId: "G-QWT83PZ7FZ",
  };

  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig(app);

  firebase.initializeApp(firebaseConfig);
  const analyticsFirebase = firebase.analytics();

  remoteConfig.settings.minimumFetchIntervalMillis = 100;

  const { abValue } = useGrowthbook();

  const logger = useConsoleLog();

  const [remotePageContent, setPageContent] = useState({});

  const [abTestVariant, setAbTestVariant] = useState();

  useEffect(() => {
    if (abValue || abValue === null) {
      setAbTestVariant(abValue);
    }
  }, [abValue]);

  useEffect(() => {
    if (abTestVariant || abTestVariant === null) {
      setData(remoteConfig, abTestVariant, devModeVariant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteConfig, devModeVariant, abTestVariant]);

  const [hideDevMode, setHideDevMode] = useState(false);

  const setData = (remoteConfig, abTestVariant, devModeVariant) => {
    if (!abTestVariant) {
      logger.error("Fallback variant: enabled");
      setHideDevMode(true);
    }

    const loadPageConfig = (data, pagePath, isProduction) => {
      const env = isProduction ? "production" : "staging";
      return JSON.parse(data[`${env}_${pagePath}`]._value);
    };

    const handleWaitlistData = (pageConfig, waitlistConfig) => {
      setWaitListPageData(waitlistConfig.waitlist_page);
      setReservePageData(pageConfig.page_content?.reserve_page);
      if (pageConfig.page_content?.reserve_page?.people_on_waitlist) {
        setPeopleOnWaitlist(
          pageConfig.page_content.reserve_page.people_on_waitlist
        );
      }
    };

    const needsWaitlistData = (pagePath) => {
      const pagesWithWaitlist = [
        "black_friday_page",
        "old_black_friday_page",
        "old_black_friday_page_duplicate",
        "old_black_friday_page_duplicate_two",
        "old_black_friday_page_duplicate_three",
        "pre_christmas_page_three",
      ];
      return pagesWithWaitlist.includes(pagePath);
    };

    const getABTestConfig = (
      data,
      isProduction,
      abTestVariant,
      devModeVariant,
      defaultPageName
    ) => {
      if (!abTestVariant && devModeVariant === null) {
        return loadPageConfig(data, defaultPageName, isProduction);
      }
      if (defaultPagePath || duplicatePlanPagePathABtest) {
        const prefix = defaultPagePath ? "new_variant_" : "new_t_variant_";

        if (typeof abTestVariant === "number" || devModeVariant !== null) {
          const variant =
            devModeVariant !== null ? devModeVariant : abTestVariant;
          const useProduction =
            typeof abTestVariant === "number" && devModeVariant === null;

          return loadPageConfig(
            data,
            `${prefix}${variant}`,
            useProduction ? isProduction : false
          );
        }
      }

      return null;
    };

    const loadQuizConfig = (data, pagePath, isProduction) => {
      const env = isProduction ? "production" : "staging";
      return JSON.parse(data[`${env}_${pagePath}`]._value);
    };

    const getQuizABTestConfig = (
      data,
      isProduction,
      abTestVariant,
      devModeVariant,
      defaultPageName
    ) => {
      if (!abTestVariant && devModeVariant === null) {
        return loadQuizConfig(data, defaultPageName, isProduction);
      }
      if (defaultPagePath || duplicatePlanPagePathABtest) {
        const prefix = defaultPagePath ? "new_quiz_variant_" : "new_t_quiz_variant_";

        if (typeof abTestVariant === "number" || devModeVariant !== null) {
          const variant =
            devModeVariant !== null ? devModeVariant : abTestVariant;
          const useProduction =
            typeof abTestVariant === "number" && devModeVariant === null;

          return loadQuizConfig(
            data,
            `${prefix}${variant}`,
            useProduction ? isProduction : false
          );
        }
      }

      return null;
    };

    const getDefaultConfig = (data, isProduction, pagePath) => {
      return loadPageConfig(data, pagePath, isProduction);
    };

    fetchAndActivate(remoteConfig)
      .then(() => {
        const data = getAll(remoteConfig);
        let pageConfig;
        let quizConfig;
        const waitlistConfig = JSON.parse(data.New_Plan_Page._value);
        const appConfig = JSON.parse(data.AppConfig._value);

        const useABTestNewProduction = appConfig.use_ab_test_default_page;
        const useABTestNewStaging =
          appConfig.use_ab_test_default_page_staging;

        const useABTestNewTproduction =
          appConfig.use_ab_test_default_page_duplicate;
        const useABTestNewTstaging =
          appConfig.use_ab_test_default_page_duplicate_staging;

        const useQuizABtestNewProduction = appConfig.use_quiz_ab_test_new_page_production;
        const useQuizABtestNewStaging = appConfig.use_quiz_ab_test_new_page_staging;

        const useQuizABtestNewTproduction = appConfig.use_quiz_ab_test_new_t_page_production;
        const useQuizABtestNewTstaging = appConfig.use_quiz_ab_test_new_t_page_staging;

        const pageTypes = {
          applyDiscount: {
            condition: applyDiscountPage,
            path: "apply_discount_page",
          },
          blackFriday: {
            condition: holidayPromoPath,
            path: "black_friday_page",
          },
          oldBlackFriday: {
            condition: holidayOldPromoPath,
            path: "old_black_friday_page",
          },
          // duplicateOldBlackFriday: {
          //   condition: holidayOldPromoPathDuplicate,
          //   path: "old_black_friday_page_duplicate",
          // },
          // duplicateTwo: {
          //   condition: holidayOldPromoPathDuplicateTwo,
          //   path: "old_black_friday_page_duplicate_two",
          // },
          duplicateThree: {
            condition: holidayOldPromoPathDuplicateThree,
            path: "old_black_friday_page_duplicate_three",
          },
          preChristmas: {
            condition: holidayPromoThreePath,
            path: "pre_christmas_page_three",
          },
          duplicatePlan: {
            condition: duplicatePlanPagePath,
            path: "new_h_fallback",
          },
        };

        const matchingPage = Object.values(pageTypes).find(
          (page) => page.condition
        );

        if (matchingPage) {
          pageConfig = loadPageConfig(data, matchingPage.path, isProduction);

          if (needsWaitlistData(matchingPage.path)) {
            handleWaitlistData(pageConfig, waitlistConfig);
          }

          quizConfig = getDefaultConfig(data, isProduction, "quiz_default")

          setQuiz(quizConfig)
          logger.warn("Used default QUIZ config")


        } else {
          if (duplicatePlanPagePathABtest) {
            const useABTest = isProduction
              ? useABTestNewTproduction
              : useABTestNewTstaging;

            const useQuizABTest = isProduction
              ? useQuizABtestNewTproduction
              : useQuizABtestNewTstaging;

            pageConfig = useABTest
              ? getABTestConfig(
                data,
                isProduction,
                abTestVariant,
                devModeVariant,
                "new_t_fallback"
              )
              : getDefaultConfig(data, isProduction, "new_t_fallback");

            logger[useABTest ? "info" : "warn"](
              "Used " +
              (useABTest ? "AB test" : "fallback") +
              " config for new-t plan page"
            );

            quizConfig = useQuizABTest ? getQuizABTestConfig(data,
              isProduction,
              abTestVariant,
              devModeVariant,
              "new_t_quiz_default") : getDefaultConfig(data, isProduction, "new_t_quiz_default")

            setQuiz(quizConfig)

            logger[useQuizABTest ? "info" : "warn"](
              "Used " +
              (useQuizABTest ? `Quiz AB test variant ${isProduction ? abTestVariant : devModeVariant}` : "default quiz") +
              " config for new-t flow", quizConfig
            );


          } else if (defaultPagePath) {
            const useABTest = isProduction
              ? useABTestNewProduction
              : useABTestNewStaging;

            const useQuizABTest = isProduction
              ? useQuizABtestNewProduction
              : useQuizABtestNewStaging;

            pageConfig = useABTest
              ? getABTestConfig(
                data,
                isProduction,
                abTestVariant,
                devModeVariant,
                "new_fallback"
              )
              : getDefaultConfig(data, isProduction, "new_fallback");

            logger[useABTest ? "info" : "warn"](
              "Used " +
              (useABTest ? "AB test" : "fallback") +
              " config for new plan page"
            );

            quizConfig = useQuizABTest ? getQuizABTestConfig(data,
              isProduction,
              abTestVariant,
              devModeVariant,
              "new_quiz_default") : getDefaultConfig(data, isProduction, "new_quiz_default")

            setQuiz(quizConfig)

            logger[useQuizABTest ? "info" : "warn"](
              "Used " +
              (useQuizABTest ? `Quiz AB test variant ${isProduction ? abTestVariant : devModeVariant}` : "default quiz") +
              " config for new flow", quizConfig
            );


          }
        }

        if (pageConfig) {
          setPageContent(pageConfig);
        }

        setEmailsToDisableAnalyticsEvent(
          appConfig.teamEmailsToDisableAnalyticsEvents
        );
        setApiGeoUrl(appConfig.appConfig.REACT_APP_GEO_API);
        setABtestCampaignName(
          appConfig.appConfig.REACT_APP_A_B_TEST_CAMPAIGN_NAME
        );
        setABtestStartCampaignDate(
          appConfig.appConfig.REACT_APP_START_TEST_CAMPAIGN_DATE
        );
        setAppData(appConfig.appConfig);
        setFbEvent(appConfig.fb_purchase_event);
        setPaymentButtons(appConfig.payment_buttons);
        setRedirectUrl(appConfig.reservePageRedirectURL);
        setEmailProviders(appConfig.emailServices);
        setQAtimerTime(appConfig.qa_timer_time);

        const pageDataConfig = JSON.parse(data.New_Plan_Page._value);
        setComponentsData(pageDataConfig.newPlanPage);
        setNewPageReviews(pageDataConfig.reviews);
        setVideoReviews(pageDataConfig.videoReviews);

        const feedbackConfig = JSON.parse(data.Feedback._value);
        setFeedbackData(feedbackConfig);

        const firebaseTextOptionData = JSON.parse(data.OptionTextConfig._value);
        const stagingFirebaseTextOptionData = JSON.parse(
          data.StagingOptionTextConfig._value
        );

        if (isProduction) {
          // const quizName = appConfig.useQuiz;
          // const quizDataFirebase = JSON.parse(data[quizName]._value);
          // setQuiz(quizDataFirebase);
          setOptionTextData(firebaseTextOptionData);
        } else {
          // const quizDataFirebase = JSON.parse(data.Quiz_Staging._value);
          // logger.info(`quizDataFirebase`, quizDataFirebase);

          // setQuiz(quizDataFirebase);
          setOptionTextData(stagingFirebaseTextOptionData);
        }
      })
      .catch((err) => {
        logger.critical(err);
      })
      .finally(() => {
        setIsConfigReady(true);
      });
  };

  if (isConfigReady === false) {
    // return <Loader />;
    return false;
  }

  const contextValue = {
    apiGeoUrl,
    abTestCampaignName,
    abTestStartCampaignDate,
    analyticsFirebase,
    paymentButtons,
    quizData,
    appData,
    feedbackData,
    componentsData,
    waitlistPageData,
    reservePageData,
    peopleOnWaitlist,
    newPageReviews,
    newPageVideoReviews,
    fbEvent,
    redirectUrl,
    emailProviders,
    emailsToDisableAnalyticsEvent,
    remotePageContent,
    optionTextData,
    hideDevMode,
    abTestVariant,
    QAtimerTime,
    isConfigReady,
  };

  return (
    <RemoteConfigContext.Provider value={contextValue}>
      {children}
    </RemoteConfigContext.Provider>
  );
};
