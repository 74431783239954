import { useState, useEffect } from "react";

export const useCleanPath = () => {
  const [cleanPath, setCleanPath] = useState(() => {
    try {
      const url = new URL(window.location.href);
      return `${url.origin}${url.pathname}`;
    } catch (error) {
      console.warn("Error getting initial clean path:", error);
      return null;
    }
  });

  useEffect(() => {
    const handleLocationChange = () => {
      try {
        const url = new URL(window.location.href);
        setCleanPath(`${url.origin}${url.pathname}`);
      } catch (error) {
        console.warn("Error handling location change:", error);
        setCleanPath(null);
      }
    };

    try {
      window.addEventListener("popstate", handleLocationChange);
      window.addEventListener("hashchange", handleLocationChange);

      const observer = new MutationObserver(handleLocationChange);
      observer.observe(document, { subtree: true, childList: true });

      return () => {
        try {
          window.removeEventListener("popstate", handleLocationChange);
          window.removeEventListener("hashchange", handleLocationChange);
          observer.disconnect();
        } catch (error) {
          console.warn("Error cleaning up observers:", error);
        }
      };
    } catch (error) {
      console.warn("Error setting up observers:", error);
      return () => {};
    }
  }, []);

  return cleanPath;
};
