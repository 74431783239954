import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useEnvContext } from "../../../context/EnvironmentContext";

import { useNavigationPath } from "../../../hooks/navigationPath";
import { preserveUrlParams } from "../../../utils/urlParams";

import EventsDevMode from "../EventsDevMode/EventsDevMode";
import DevMode from "../DevMode/DevMode";
import EmulateProduction from "../EmulateProduction/EmulateProduction";
import SetTimerTime from "../SetTimerTime/SetTimerTime";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import EmulateError from "../EmulateError/EmulateError";

import styles from "./DevNavBarStyle.module.css";

const DevNavBar = () => {
  const { isProduction } = useEnvContext();

  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const navigate = useNavigate();
  const navigatePlanPagePath = useNavigationPath("plan");

  const navigateTo = (navPath) => {
    navigate(preserveUrlParams(navPath));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const MenuContainer = ({ text, component: Component }) => (
    <div className={styles.menuItem}>
      <div className={styles.menuItemEvents}>
        <span className={styles.menuItemtext}>{text}</span>
        <Component />
      </div>
    </div>
  );

  const ButtonMenuContainer = ({ text, callback }) => (
    <div className={styles.menuItem}>
      <div className={styles.menuItemEvents}>
        <ButtonComponent text={text} callback={callback} />
      </div>
    </div>
  );

  if (isProduction) return null;

  return (
    <div className={styles.container} ref={popupRef}>
      <button
        className={`${styles.menuButton} ${isOpen ? styles.active : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.menuIcon}>⚙️</div>
      </button>

      {isOpen && (
        <div className={styles.popup}>
          <div className={styles.popupHeader}>Dev Tools</div>
          <div className={styles.popupContent}>
            <MenuContainer text="" component={DevMode} />
            <MenuContainer text="" component={SetTimerTime} />
            <MenuContainer text="Stage Events" component={EventsDevMode} />
            <MenuContainer text="Emulate Prod" component={EmulateProduction} />
            <MenuContainer text="Payment Err" component={EmulateError} />
            <ButtonMenuContainer
              text="Plan page"
              callback={() => navigateTo(navigatePlanPagePath)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DevNavBar;
