import React, { useState, useEffect } from "react";
import QuizLoader from "./QuizLoader/QuizLoader";
import { Step } from "../../styles";

const LoaderStep = React.memo(({ id, step, handleNextStep }) => {
  const [resLoader, setResLoader] = useState(true);
  useEffect(() => {
    if (!resLoader) {
      handleNextStep({ id, type: "loader" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resLoader]);
  return (
    <Step>
      <QuizLoader data={step} setResLoader={setResLoader} />
    </Step>
  );
});

export default LoaderStep;
