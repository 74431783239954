import { useState, useEffect } from "react";
import { useUrlContext } from "../context/urlContext";

const createPathBuilder = (segment) => ({
  build: (context) => {
    const {
      applyDiscountPage,
      holidayPromoPath,
      holidayOldPromoPath,
      holidayOldPromoPathDuplicateThree,
      holidayPromoThreePath,
      duplicatePlanPagePath,
      duplicatePlanPagePathABtest,
    } = context;
    if (duplicatePlanPagePathABtest) return `/new-t/${segment}`;
    if (duplicatePlanPagePath) return `/new-h/${segment}`;
    if (applyDiscountPage) return `/apply-discount/${segment}`;
    if (holidayPromoPath) return `/hp/${segment}`;
    if (holidayOldPromoPath) return `/hp-23/${segment}`;
    if (holidayOldPromoPathDuplicateThree) return `/hp-23d3/${segment}`;
    if (holidayPromoThreePath) return `/hp-3/${segment}`;
    return `/new/${segment}`;
  },
});

const PATHS = {
  quiz: createPathBuilder("quiz/1"),
  plan: createPathBuilder("plan"),
  applyDiscount: createPathBuilder("apply-discount"),
  reserve: createPathBuilder("reserve"),
  reserveTrial: createPathBuilder("reserve-essential"),
  waitlist: createPathBuilder("waitlist"),
  thankyou: createPathBuilder("thankyou"),
  payment: createPathBuilder("payment"),
};

export const useNavigationPath = (key) => {
  const context = useUrlContext();
  const [path, setPath] = useState("");

  if (!context) {
    throw new Error("UrlContext.Provider not found");
  }

  useEffect(() => {
    setPath(PATHS[key].build(context));
  }, [context, key]);

  return path;
};
