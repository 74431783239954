import React from "react";

import ToggleComponent from "../ToggleComponent/ToggleComponent";
import { useDevMode } from "../../../context/DevMode";

const EmulateError = () => {
  const { emulateUnexpectError, unexpectError } = useDevMode();
  return (
    <ToggleComponent status={emulateUnexpectError} callback={unexpectError} />
  );
};

export default EmulateError;
