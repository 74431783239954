import { USER_ACTIONS } from "./types";

const defaultInitialState = {
  cid: null,
  gcid: null,
  email: null,
  name: null,
  email_linked: null,
  user_order_id: null,
  social_provider: null,
  social_profile_email: null,
  analytics_tracked: {
    eventsTracked: false,
    lastTrackedValues: {
      cid: null,
      email: null,
      name: null,
    },
  },
};

const getStoredState = () => {
  try {
    const storedData = localStorage.getItem("MemoryOSuState");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      return { ...defaultInitialState, ...parsedData };
    }
  } catch (error) {
    console.error("Error reading from localStorage:", error);
  }
  return defaultInitialState;
};

export const initialState = getStoredState();

export const userDataReducer = (state, action) => {
  switch (action.type) {
    case USER_ACTIONS.SET_CID:
      return {
        ...state,
        cid: action.payload,
      };
    case USER_ACTIONS.SET_GCID:
      return {
        ...state,
        gcid: action.payload,
      };
    case USER_ACTIONS.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case USER_ACTIONS.SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case USER_ACTIONS.SET_LINKED_EMAIL:
      return {
        ...state,
        email_linked: action.payload,
      };
    case USER_ACTIONS.SET_USER_ORDER_ID:
      return {
        ...state,
        user_order_id: action.payload,
      };
    case USER_ACTIONS.SET_SOCIAL_PROVIDER:
      return {
        ...state,
        social_provider: action.payload,
      };
    case USER_ACTIONS.SET_SOCIAL_PROFILE_EMAIL:
      return {
        ...state,
        social_profile_email: action.payload,
      };
    case USER_ACTIONS.SET_ANALYTICS_TRACKED:
      return {
        ...state,
        analytics_tracked: action.payload,
      };
    default:
      return state;
  }
};

// const defaultInitialState = {
//   cid: null,
//   gcid: null,
//   email: null,
//   name: null,
//   email_linked: null,
//   user_order_id: null,
//   social_provider: null,
//   social_profile_email: null,
// };

// const getStoredState = () => {
//   try {
//     const storedData = localStorage.getItem("MemoryOSuState");
//     if (storedData) {
//       const parsedData = JSON.parse(storedData);
//       return { ...defaultInitialState, ...parsedData };
//     }
//   } catch (error) {
//     console.error("Error reading from localStorage:", error);
//   }
//   return defaultInitialState;
// };

// export const initialState = getStoredState();

// export const userDataReducer = (state, action) => {
//   switch (action.type) {
//     case USER_ACTIONS.SET_CID:
//       return {
//         ...state,
//         cid: action.payload,
//       };
//     case USER_ACTIONS.SET_GCID:
//       return {
//         ...state,
//         gcid: action.payload,
//       };
//     case USER_ACTIONS.SET_EMAIL:
//       return {
//         ...state,
//         email: action.payload,
//       };
//     case USER_ACTIONS.SET_NAME:
//       return {
//         ...state,
//         name: action.payload,
//       };
//     case USER_ACTIONS.SET_LINKED_EMAIL:
//       return {
//         ...state,
//         email_linked: action.payload,
//       };
//     case USER_ACTIONS.SET_USER_ORDER_ID:
//       return {
//         ...state,
//         user_order_id: action.payload,
//       };
//     case USER_ACTIONS.SET_SOCIAL_PROVIDER:
//       return {
//         ...state,
//         social_provider: action.payload,
//       };
//     case USER_ACTIONS.SET_SOCIAL_PROFILE_EMAIL:
//       return {
//         ...state,
//         social_profile_email: action.payload,
//       };
//     default:
//       return state;
//   }
// };
