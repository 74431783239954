export const USER_ACTIONS = {
  SET_CID: "SET_CID",
  SET_EMAIL: "SET_EMAIL",
  SET_NAME: "SET_NAME",
  SET_GCID: "SET_GCID",
  SET_LINKED_EMAIL: "SET_LINKED_EMAIL",
  SET_USER_ORDER_ID: "SET_USER_ORDER_ID",
  SET_SOCIAL_PROVIDER: "SET_SOCIAL_PROVIDER",
  SET_SOCIAL_PROFILE_EMAIL: "SET_SOCIAL_PROFILE_EMAIL",
  SET_ANALYTICS_TRACKED: "SET_ANALYTICS_TRACKED",
};
