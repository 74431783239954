import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCleanPath } from "../../../hooks/useCleanPath";
import { useNavigationPath } from "../../../hooks/navigationPath";
import { preserveUrlParams } from "../../../utils/urlParams";

import { usePageConfig } from "../../../context/PageConfigContext";
import { useEvent } from "../../../context/EventContext";
import { usePaymentBlock } from "../../../context/PaymentBlockContext";
import { useUserData } from "../../../context/UserData/UserDataContext";
import { useUrlContext } from "../../../context/urlContext";

import EmailContainer from "./EmailContainer/EmailContainer";
import UserNameContainer from "./UserNameContainer/UserNameContainer";

import styles from "./UserNameEmailStepStyle.module.css";

const UserNameEmailStep = () => {
  const { setStartNameEmailForm, loadingForm } = usePaymentBlock();

  const { analyticsEvent, setSnaptrUserData } = useEvent();
  const { sendQuizRequest, findDataComponent } = usePageConfig();

  const { validHolidayPathsCheck } = useUrlContext();

  const data = findDataComponent("UserNameEmailStep");

  const { setStateUserEmail, setStateUserName } = useUserData();

  const [validUserData, setValidUserData] = useState([false, false]);

  const updateValidUserDataAtIndex = (index, value) => {
    setValidUserData((prevValidUserData) => {
      const newValidUserData = [...prevValidUserData];
      newValidUserData[index] = value;
      return newValidUserData;
    });
  };

  const [userData, setUserData] = useState({});

  const updateUserData = (newData) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      ...newData,
    }));
  };

  const navigate = useNavigate();
  const cleanPath = useCleanPath();
  const reservePath = useNavigationPath("reserve");

  const navigateUserNameEmailBlock = () => {
    if (validHolidayPathsCheck) {
      const checkWaitlist = /\/waitlist(\/|$|\?|#)/.test(cleanPath);
      if (checkWaitlist) {
        console.log(`checkWaitlist`, checkWaitlist);
        navigate(preserveUrlParams(reservePath));
      } else {
        loadingForm("StartPaymentForm");
      }
    } else {
      loadingForm("StartPaymentForm");
    }
  };

  const handleSubmit = async () => {
    if (validUserData.every((value) => value === true)) {
      await setStateUserEmail(userData.userEmail);
      await setStateUserName(userData.userName);

      analyticsEvent("conversion");

      localStorage.setItem("MemoryOSUserData", JSON.stringify(userData));
      setSnaptrUserData(userData);

      const findQuizData = localStorage.getItem("MemoryOSQuizData");
      if (findQuizData !== null) {
        sendQuizRequest();
      }

      setStartNameEmailForm(false);
      navigateUserNameEmailBlock();
      // loadingForm("StartPaymentForm");
    }
  };
  return (
    <div className={styles.ShadowPopup}>
      <div className={styles.containerUserNameEmailStep}>
        <button
          className={styles.closeButton}
          onClick={() => {
            setStartNameEmailForm(false);
          }}
        >
          X
        </button>
        <div className={styles.titleContainer}>
          <span className={styles.titleText}>{data.title}</span>
        </div>
        <UserNameContainer
          placeholderInfo={data.inputPlaceholder[0]}
          updateUserData={updateUserData}
          updateValidUserDataAtIndex={updateValidUserDataAtIndex}
        />
        <EmailContainer
          placeholderInfo={data.inputPlaceholder[1]}
          invalidEmail={data.inputPlaceholder[2]}
          updateUserData={updateUserData}
          updateValidUserDataAtIndex={updateValidUserDataAtIndex}
        />

        <button
          className={
            validUserData.every((value) => value === true)
              ? styles.buttonStyle
              : styles.buttonStyleDisable
          }
          onClick={() => {
            handleSubmit();
          }}
        >
          {data.buttonText}
        </button>
        <div className={styles.noteContainer}>
          <span className={styles.noteText}>
            {data.noteContainer.headerNoteText}
          </span>{" "}
          <a
            href={data.noteContainer.termsConditionsLink}
            rel="noreferrer"
            target="_blank"
            className={styles.link}
          >
            {data.noteContainer.termsConditionsText}
          </a>{" "}
          <span className={styles.noteText}>&</span>{" "}
          <a
            href={data.noteContainer.privacyPolicyLink}
            rel="noreferrer"
            target="_blank"
            className={styles.link}
          >
            {data.noteContainer.privacyPolicyText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default UserNameEmailStep;
