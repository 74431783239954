import React from "react";

import styles from "./LinkedBlockStyle.module.css";

const LinkedBlock = ({ data }) => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>{data.title}</span>
      <span className={styles.subTitle}>{data.subTitle}</span>
    </div>
  );
};

export default LinkedBlock;
