import React from "react";
// import useUniqueComponentIds from "../../../../hooks/useUniqueComponentIds";

import styles from "./KickstarterStepStyle.module.css";

const KickstarterStep = React.memo(({ id, step, handleNextStep }) => {
  // const ids = useUniqueComponentIds("KickstarterStep", id, step);
  return (
    <div className={styles.container}>
      {/* <span className={styles.title} id={ids.forElement("title")}> */}
      <span className={styles.title} id={step.title_id}>
        {step.title}
      </span>
      {/* <span className={styles.subTitle} id={ids.forElement("subTitle")}> */}
      <span className={styles.subTitle} id={step.sub_title_id}>
        {step.subTitle}
      </span>
      <div className={styles.mainContainer}>
        <span className={styles.topText}>{step.mainText[0]}</span>
        <span className={styles.mainText}>{step.mainText[1]}</span>
      </div>
      <span className={styles.imgHeader}>{step.infoText}</span>
      <div className={styles.imgContainer}>
        <img
          src={step.images.the_times}
          alt="TheTimes"
          className={styles.imgTheTimes}
        />
        <img src={step.images.bbc} alt="BBC" className={styles.imgBbc} />
        <img
          src={step.images.the_telegraph}
          alt="TheTelegraph"
          className={styles.imgTheTelegraph}
        />
        <img
          src={step.images.tech_cranch}
          alt="TechCranch"
          className={styles.imgTechCranch}
        />
        <img
          src={step.images.the_new_york_times}
          alt="TheNewYorkTimes"
          className={styles.imgTheNewYorkTimes}
        />
        <img
          src={step.images.independent}
          alt="Independent"
          className={styles.imgIndependent}
        />
      </div>
      <button
        onClick={() => handleNextStep({ id, type: "kickstarter" })}
        className={styles.button}
      >
        <span className={styles.buttonText}>Continue</span>
      </button>
    </div>
  );
});

export default KickstarterStep;
