import React from "react";
import useUniqueComponentIds from "../../../../hooks/useUniqueComponentIds";

import {
  Step,
  Column,
  Title,
  Image,
  Copy,
  Banner,
  BannerHead,
  BannerName,
  BannerRating,
  Star,
  BannerCopy,
  Button,
} from "../../styles";
import VideoPlayer from "../../../templates/VideoPlayer/VideoPlayer";

const RatingStep = React.memo(({ id, step, handleNextStep }) => {
  const ids = useUniqueComponentIds("RatingStep", id, step);

  return (
    <Step>
      <Column>
        <Image>
          <img src={step.image} alt="" />
        </Image>
        {step.video ? (
          <div>
            <VideoPlayer
              videoId={step.video}
              thumbnail={step.thumbnail || ""}
            />
          </div>
        ) : null}
      </Column>
      <Column>
        <Title id={ids.forElement("title")}>{step.title}</Title>
        {step.copy.map((copy, index) => (
          <Copy key={`${id}-${index}`} id={ids.forElement(`copy_${index}`)}>
            {copy}
          </Copy>
        ))}
        <Banner>
          <BannerHead>
            <BannerName id={ids.forElement("banner")}>
              {step.banner.name}
            </BannerName>
            <BannerRating>
              {[...Array(5)].map((item, i) => (
                <Star
                  key={`star${id}-${i}`}
                  $isActive={!!(i < step.banner.stars)}
                />
              ))}
            </BannerRating>
          </BannerHead>
          <BannerCopy id={ids.forElement("banner_copy")}>
            {step.banner.copy}
          </BannerCopy>
        </Banner>
        <Button
          $isActive={true}
          onClick={() => handleNextStep({ id, type: "rating" })}
        >
          Continue
        </Button>
      </Column>
    </Step>
  );
});

export default RatingStep;
