import React from "react";
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import StepThree from "./StepThree/StepThree";

import styles from "./StepsContainer.module.css";

const StepsContainer = ({ dataComponent, showHideParagraph }) => {
  return (
    <div className={styles.container}>
      <p className={styles.actPlanText}>{dataComponent.activateText.header}</p>
      <StepOne dataComponent={dataComponent.activateText.stepOne} />
      <StepTwo dataComponent={dataComponent.activateText.stepTwo} />
      <StepThree
        dataComponent={dataComponent.activateText.stepThree}
        showHideParagraph={showHideParagraph}
      />
    </div>
  );
};

export default StepsContainer;
