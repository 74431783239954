import { useState, useEffect } from "react";

const APP_VERSION_KEY = "MemoryOSwebAppV";

function compareVersions(newVersion, oldVersion) {
  const newParts = newVersion.split(".").map(Number);
  const oldParts = oldVersion.split(".").map(Number);

  for (let i = 0; i < Math.max(newParts.length, oldParts.length); i++) {
    const newPart = newParts[i] || 0;
    const oldPart = oldParts[i] || 0;

    if (newPart > oldPart) return true;
    if (newPart < oldPart) return false;
  }

  return false;
}

async function clearAllCaches() {
  try {
    if (!("caches" in window)) return false;

    const cacheKeys = await caches.keys();
    await Promise.all(
      cacheKeys.map((key) => {
        console.log("Deleting cache:", key);
        return caches.delete(key);
      })
    );
    console.log("All caches deleted");
    return true;
  } catch (error) {
    console.error("Failed to clear caches:", error);
    return false;
  }
}

async function removeAllServiceWorkers() {
  if (!("serviceWorker" in navigator)) return false;

  try {
    const registrations = await navigator.serviceWorker.getRegistrations();
    
    await Promise.all(
      registrations.map(async (registration) => {
        console.log("Unregistering service worker:", registration.scope);
        return registration.unregister();
      })
    );
    
    console.log("All service workers unregistered");
    return true;
  } catch (error) {
    console.error("Failed to remove service workers:", error);
    return false;
  }
}

export function useVersionCheck(version) {
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const storedVersion = localStorage.getItem(APP_VERSION_KEY);
        const isFirstLoad = !storedVersion;

        if (isFirstLoad) {
          localStorage.setItem(APP_VERSION_KEY, version);
          setIsChecking(false);
          return;
        }

        if (compareVersions(version, storedVersion)) {
          console.log(
            `Version update detected: ${storedVersion} -> ${version}`
          );
          
          await clearAllCaches();
          await removeAllServiceWorkers();
          
          localStorage.clear();
          localStorage.setItem(APP_VERSION_KEY, version);
        }
        
        setIsChecking(false);
      } catch (error) {
        console.error("Version check failed:", error);
        setIsChecking(false);
      }
    };

    checkVersion();
  }, [version]);

  return isChecking;
}

// import { useState, useEffect } from "react";

// const APP_VERSION_KEY = "MemoryOSwebAppV";

// function compareVersions(newVersion, oldVersion) {
//   const newParts = newVersion.split(".").map(Number);
//   const oldParts = oldVersion.split(".").map(Number);

//   for (let i = 0; i < Math.max(newParts.length, oldParts.length); i++) {
//     const newPart = newParts[i] || 0;
//     const oldPart = oldParts[i] || 0;

//     if (newPart > oldPart) return true;
//     if (newPart < oldPart) return false;
//   }

//   return false;
// }

// export function useVersionCheck(version) {
//   const [isChecking, setIsChecking] = useState(true);

//   useEffect(() => {
//     const checkVersion = () => {
//       try {
//         const storedVersion = localStorage.getItem(APP_VERSION_KEY);
//         const isFirstLoad = !storedVersion;

//         if (isFirstLoad) {
//           localStorage.setItem(APP_VERSION_KEY, version);
//           setIsChecking(false);
//           return;
//         }

//         if (compareVersions(version, storedVersion)) {
//           console.log(
//             `Version update detected: ${storedVersion} -> ${version}`
//           );
          
//           localStorage.clear();
//           localStorage.setItem(APP_VERSION_KEY, version);
//         }
        
//         setIsChecking(false);
//       } catch (error) {
//         console.error("Version check failed:", error);
//         setIsChecking(false);
//       }
//     };

//     checkVersion();
//   }, [version]);

//   return isChecking;
// }


// import { useEffect, useState } from "react";
// import Loader from "../../pages/templates/Loader/Loader";
// import { WebViewDetector } from "../../utils/WebViewDetector";


// const APP_VERSION_KEY = "MemoryOSwebAppV";
// const IS_VERSION_CHECK_ENABLED = true;

// function compareVersions(newVersion, oldVersion) {
//   const newParts = newVersion.split(".").map(Number);
//   const oldParts = oldVersion.split(".").map(Number);

//   for (let i = 0; i < Math.max(newParts.length, oldParts.length); i++) {
//     const newPart = newParts[i] || 0;
//     const oldPart = oldParts[i] || 0;

//     if (newPart > oldPart) return true;
//     if (newPart < oldPart) return false;
//   }

//   return false;
// }

// async function clearAllCaches() {
//   try {
//     if (!("caches" in window)) return false;

//     const cacheKeys = await caches.keys();
//     await Promise.all(
//       cacheKeys.map((key) => {
//         console.log("Deleting cache:", key);
//         return caches.delete(key);
//       })
//     );
//     console.log("All caches deleted");
//     return true;
//   } catch (error) {
//     console.error("Failed to clear caches:", error);
//     return false;
//   }
// }

// async function updateServiceWorker() {
//   if (!("serviceWorker" in navigator)) return false;

//   try {
//     const registrations = await navigator.serviceWorker.getRegistrations();

//     await Promise.all(
//       registrations.map(async (registration) => {
//         if (registration.waiting) {
//           console.log("Activating waiting service worker");
//           registration.waiting.postMessage({ type: "SKIP_WAITING" });
//         } else {
//           console.log("Updating service worker");
//           await registration.update();
//         }
//       })
//     );

//     return true;
//   } catch (error) {
//     console.error("Failed to update service worker:", error);
//     return false;
//   }
// }

// export function useVersionCheck(version) {
//   const [isChecking, setIsChecking] = useState(true);
//   const [isUpdating, setIsUpdating] = useState(false);
//   const [isWebView, setIsWebView] = useState(false);

//   useEffect(() => {
//     const isInWebView = WebViewDetector.isRunningInWebView();
//     setIsWebView(isInWebView);
    
//     if (isInWebView) {
//       console.log("Running in WebView - skipping normal version check procedure");
//       const storedVersion = localStorage.getItem(APP_VERSION_KEY);
//       if (!storedVersion || compareVersions(version, storedVersion)) {
//         localStorage.clear();
//         localStorage.setItem(APP_VERSION_KEY, version);
//       }
//       setIsChecking(false);
//     }
//   }, [version]);

//   useEffect(() => {
//     let isMounted = true;
//     let timeoutId = null;

//     if (isWebView) {
//       return;
//     }

//     const checkVersion = async () => {
//       try {
//         if (!IS_VERSION_CHECK_ENABLED) {
//           if (isMounted) setIsChecking(false);
//           return;
//         }

//         const storedVersion = localStorage.getItem(APP_VERSION_KEY);

//         const isFirstLoad = !storedVersion;

//         if (isFirstLoad) {
//           localStorage.setItem(APP_VERSION_KEY, version);
//           if (isMounted) setIsChecking(false);
//           return;
//         }

//         if (compareVersions(version, storedVersion)) {
//           console.log(
//             `Version update detected: ${storedVersion} -> ${version}`
//           );

//           if (isMounted) setIsUpdating(true);

//           await clearAllCaches();
//           await updateServiceWorker();

//           localStorage.clear();
//           localStorage.setItem(APP_VERSION_KEY, version);

//           const controllerChangePromise = new Promise((resolve) => {
//             if ("serviceWorker" in navigator) {
//               navigator.serviceWorker.addEventListener(
//                 "controllerchange",
//                 () => {
//                   console.log(
//                     "New service worker controlling the page, reloading..."
//                   );
//                   resolve("controller_changed");
//                 },
//                 { once: true }
//               );
//             } else {
//               resolve("no_sw_support");
//             }
//           });

//           timeoutId = setTimeout(() => {
//             console.log("Reloading page after version update timeout");
//             if (isMounted) window.location.reload();
//           }, 500);

//           const result = await Promise.race([
//             controllerChangePromise,
//             new Promise((r) => setTimeout(() => r("timeout"), 500)),
//           ]);

//           if (result === "controller_changed") {
//             clearTimeout(timeoutId);
//             if (isMounted) window.location.reload();
//           }

//           return;
//         }

//         if (
//           "serviceWorker" in navigator &&
//           navigator.serviceWorker.controller
//         ) {
//           const registrations =
//             await navigator.serviceWorker.getRegistrations();
//           for (const registration of registrations) {
//             if (registration.waiting) {
//               console.log("Found waiting service worker, activating...");

//               if (isMounted) setIsUpdating(true);

//               const controllerChangePromise = new Promise((resolve) => {
//                 navigator.serviceWorker.addEventListener(
//                   "controllerchange",
//                   () => {
//                     console.log("Service worker controller changed");
//                     resolve();
//                   },
//                   { once: true }
//                 );
//               });

//               registration.waiting.postMessage({ type: "SKIP_WAITING" });

//               await Promise.race([
//                 controllerChangePromise,
//                 new Promise((r) => setTimeout(r, 500)),
//               ]);

//               if (isMounted) {
//                 window.location.reload();
//                 return;
//               }
//             }
//           }
//         }

//         if (isMounted) setIsChecking(false);
//       } catch (error) {
//         console.error("Version check failed:", error);
//         if (isMounted) {
//           setIsChecking(false);
//           setIsUpdating(false);
//         }
//       }
//     };

//     checkVersion();

//     return () => {
//       isMounted = false;
//       if (timeoutId) clearTimeout(timeoutId);
//     };
//   }, [version, isWebView]);

//   if (isUpdating) {
//     return <Loader />;
//   }

//   return isChecking;}