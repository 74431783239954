import { useState, useEffect } from "react";

export const useCurrentPath = () => {
  const [currentPath, setCurrentPath] = useState(() => {
    try {
      return window.location.href;
    } catch (error) {
      console.warn("Error getting initial current path:", error);
      return "";
    }
  });

  useEffect(() => {
    const handleLocationChange = () => {
      try {
        setCurrentPath(window.location.href);
      } catch (error) {
        console.warn("Error handling location change:", error);
      }
    };

    try {
      window.addEventListener("popstate", handleLocationChange);
      window.addEventListener("hashchange", handleLocationChange);

      const observer = new MutationObserver(handleLocationChange);
      observer.observe(document, { subtree: true, childList: true });

      return () => {
        try {
          window.removeEventListener("popstate", handleLocationChange);
          window.removeEventListener("hashchange", handleLocationChange);
          observer.disconnect();
        } catch (error) {
          console.warn("Error cleaning up observers:", error);
        }
      };
    } catch (error) {
      console.warn("Error setting up observers:", error);
      return () => {};
    }
  }, []);

  return currentPath;
};
