import React from "react";

import useUniqueComponentIds from "../../../../hooks/useUniqueComponentIds";

import {
  Step,
  Column,
  Title,
  Image,
  Copy,
  Answers,
  Answer,
  MobileAnswers,
  MobileAnswer,
} from "../../styles";
import VideoPlayer from "../../../templates/VideoPlayer/VideoPlayer";

const SingleStep = React.memo(({ id, step, handleNextStep, userData }) => {
  const currentName =
    userData.find(
      (elem) => elem.formValues && elem.formValues.hasOwnProperty("firstName")
    )?.formValues?.firstName || "";

  const getSummaryTitle = (title) => {
    const modifiedTitle = title.replace(/\{showName\}/g, `${currentName}`);
    return modifiedTitle;
  };

  const ids = useUniqueComponentIds("SingleStep", id, step);

  return (
    <Step>
      <Column>
        {/* <Title id={ids.forElement("title")}> */}
        <Title id={step.title_id}>
          {currentName ? getSummaryTitle(step.title) : step.title}
        </Title>
        {step.image ? (
          <Image>
            <img src={step.image} alt="" />
          </Image>
        ) : null}
        {step.copy
          ? step.copy.map((copy, index) => (
              <Copy key={`${id}-${index}`} id={`${step.sub_title_id}${index}`}>
                {copy}
              </Copy>
            ))
          : null}
        {step.video ? (
          <div>
            <VideoPlayer
              videoId={step.video}
              thumbnail={step.thumbnail || ""}
            />
          </div>
        ) : null}
      </Column>
      <Column>
        <Answers $hideOnMobile={step.subtype && step.subtype === "rate"}>
          {step.answers.map((answer, index) => (
            <Answer
              key={`${id}-${index}`}
              id={ids.forElement(`answer${index}`)}
              onClick={() =>
                handleNextStep({ id, answer, answerID: index, type: "single" })
              }
            >
              {answer}
            </Answer>
          ))}
        </Answers>
        {step.subtype && step.subtype === "rate" ? (
          <MobileAnswers>
            <MobileAnswer
              large
              onClick={() =>
                handleNextStep({
                  id,
                  answer: step.answers[0],
                  answerID: 0,
                  type: "single",
                })
              }
            >
              <span>😩</span>
            </MobileAnswer>
            <MobileAnswer
              onClick={() =>
                handleNextStep({
                  id,
                  answer: step.answers[1],
                  answerID: 1,
                  type: "single",
                })
              }
            >
              <span>😞</span>
            </MobileAnswer>
            <MobileAnswer
              onClick={() =>
                handleNextStep({
                  id,
                  answer: step.answers[2],
                  answerID: 2,
                  type: "single",
                })
              }
            >
              <span>🙁</span>
            </MobileAnswer>
            <MobileAnswer
              onClick={() =>
                handleNextStep({
                  id,
                  answer: step.answers[3],
                  answerID: 3,
                  type: "single",
                })
              }
            >
              <span>😐</span>
            </MobileAnswer>
            <MobileAnswer
              large
              onClick={() =>
                handleNextStep({
                  id,
                  answer: step.answers[4],
                  answerID: 4,
                  type: "single",
                })
              }
            >
              <span>🙂</span>
            </MobileAnswer>
          </MobileAnswers>
        ) : null}
      </Column>
    </Step>
  );
});

export default SingleStep;
