import React from "react";
import useUniqueComponentIds from "../../../../hooks/useUniqueComponentIds";

import {
  Step,
  Column,
  Title,
  Image,
  Summary,
  SummaryItem,
  SummaryTitle,
  SummaryField,
  Button,
} from "../../styles";
import VideoPlayer from "../../../templates/VideoPlayer/VideoPlayer";

const SummaryStep = React.memo(({ id, step, handleNextStep, userData }) => {
  const ids = useUniqueComponentIds("SummaryStep", id, step);

  const getSummaryTitle = (title) => {
    const userName = userData.find((elem) =>
      elem.formValues?.hasOwnProperty("firstName")
    )?.formValues?.firstName;

    const modifiedTitle = title.replace(/\{showName\}/g, `${userName}`);
    return modifiedTitle !== undefined ? modifiedTitle : title;
  };

  return (
    <>
      {userData.length ? (
        <Step>
          <Column>
            <Image>
              <img src={step.image} alt="" />
            </Image>
            {step.video ? (
              <div>
                <VideoPlayer
                  videoId={step.video}
                  thumbnail={step.thumbnail || ""}
                />
              </div>
            ) : null}
          </Column>
          <Column>
            <Title id={ids.forElement("title")}>
              {getSummaryTitle(step.title)}
            </Title>
            <Summary>
              {step.fields.map((field, index) => (
                <SummaryItem key={index}>
                  <SummaryTitle>{field.title}</SummaryTitle>
                  {userData[field.step].answer && (
                    <SummaryField>{userData[field.step].answer}</SummaryField>
                  )}
                  {userData[field.step]?.answers?.map(
                    (answer, index) =>
                      answer.selected && (
                        <SummaryField key={index}>{answer.copy}</SummaryField>
                      )
                  )}
                </SummaryItem>
              ))}
              <Button
                $isActive={true}
                onClick={() => {
                  handleNextStep({ id, type: "summary" });
                }}
              >
                Show my personal plan
              </Button>
            </Summary>
          </Column>
        </Step>
      ) : null}
    </>
  );
});

export default SummaryStep;
