import React from "react";

import HasActiveSubscModal from "./HasActiveSubscModal/HasActiveSubscModal";
import PaymentErrorModal from "./PaymentErrorModal/PaymentErrorModal";

import styles from "./ErrorPopupStyles.module.css";
import { useDevMode } from "../../context/DevMode";
import { useEnvContext } from "../../context/EnvironmentContext";

const ErrorPopup = ({ errorCode }) => {
  const { emulateUnexpectError } = useDevMode();
  const { isProduction } = useEnvContext();

  const getComponentByErrorCode = (errorCode) => {
    switch (errorCode) {
      case "AlreadyHasActiveSubscription":
        if (!isProduction && emulateUnexpectError) {
          return <PaymentErrorModal errorCode={errorCode} />;
        }
        return <HasActiveSubscModal />;

      default:
        return <PaymentErrorModal errorCode={errorCode} />;
    }
  };

  return (
    <div className={styles.backgroundContainer} style={{ zIndex: "10000" }}>
      {getComponentByErrorCode(errorCode)}
    </div>
  );
};

export default ErrorPopup;
