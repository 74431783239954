import React from "react";
// import useUniqueComponentIds from "../../../../hooks/useUniqueComponentIds";

import styles from "./JonasStepStyle.module.css";

const JonasStep = React.memo(({ id, step, handleNextStep }) => {
  // const ids = useUniqueComponentIds("JonasStep", id, step);

  return (
    <div className={styles.container}>
      <img src={step.image} alt="quizJonas" className={styles.imgJonas} />
      {/* <span className={styles.title} id={ids.forElement("title")}> */}
      <span className={styles.title} id={step.title_id}>
        {step.title}
      </span>
      {/* <span className={styles.subTitle} id={ids.forElement("subTitle")}> */}
      <span className={styles.subTitle} id={step.sub_title_id}>
        {step.subTitle}
      </span>
      <div className={styles.hr} />
      <div className={styles.mainContainer}>
        {step.mainText.map((element, index) => {
          return (
            <div key={index} className={styles.element}>
              <img
                src="../../img/quizCup.png"
                alt="quizCup"
                className={styles.elementImg}
              />
              <span className={styles.elementText}>{element}</span>
            </div>
          );
        })}
      </div>
      <button
        onClick={() => handleNextStep({ id, type: "jonas" })}
        className={styles.button}
      >
        <span className={styles.buttonText}>Continue</span>
      </button>
    </div>
  );
});

export default JonasStep;
