import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useNavigationPath } from "../../hooks/navigationPath";
import { hashString } from "../../utils/hash";
import { preserveUrlParams } from "../../utils/urlParams";

import { useEvent } from "../../context/EventContext";
import { usePageConfig } from "../../context/PageConfigContext";
import { useUrlContext } from "../../context/urlContext";

import ProgressBarModule from "./modules/ProgressBarModule/ProgressBarModule";
import ButtonModule from "./modules/ButtonModule/ButtonModule";
import LogoModule from "./modules/LogoModule/LogoModule";

import EmailStep from "./components/EmailStep/EmailStep";
import SingleStep from "./components/SingleStep/SingleStep";
import StaticStep from "./components/StaticStep/StaticStep";
import MultipleStep from "./components/MultipleStep/MultipleStep";
import RatingStep from "./components/RatingStep/RatingStep";
import FormStep from "./components/FormStep/FormStep";
import PlanStep from "./components/PlanStep/PlanStep";
import SummaryStep from "./components/SummaryStep/SummaryStep";
import LoaderStep from "./components/LoaderStep/LoaderStep";
import ReviewStep from "./components/ReviewStep/ReviewStep";
import TrialStep from "./components/TrialStep/TrialStep";
import AgeStep from "./components/AgeStep/AgeStep";
import KickstarterStep from "./components/KickstarterStep/KickstarterStep";
import JonasStep from "./components/JonasStep/JonasStep";
import GenderStep from "./components/GenderStep/GenderStep";

import { Page, Container, Header } from "./styles";
import styles from "./indexStyle.module.css";

const NewQuiz = () => {
  const {
    holidayPromoPath,
    holidayOldPromoPath,
    holidayOldPromoPathDuplicateThree,
    holidayPromoThreePath,
    duplicatePlanPagePath,
    duplicatePlanPagePathABtest,
    validHolidayPathsCheck,
  } = useUrlContext();

  const {
    currentQuiz,
    // findUserData,
    sendQuizRequest,
  } = usePageConfig();

  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [userData, setUserData] = useState([]);
  const [quizId, setQuizId] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  const { analyticsEvent } = useEvent();

  const [quiz, setQuiz] = useState(null);
  useEffect(() => {
    setQuiz(currentQuiz);
  }, [currentQuiz]);

  useEffect(() => {
    analyticsEvent("quizPageView", currentStep + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    analyticsEvent("quizStart");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updatePath, setUpdatePath] = useState("");

  const waitlist = useNavigationPath("waitlist");

  const plan = useNavigationPath("plan");

  useEffect(() => {
    if (holidayPromoPath) {
      setUpdatePath("hp");
    } else if (holidayOldPromoPath) {
      setUpdatePath("hp-23");
    } else if (holidayOldPromoPathDuplicateThree) {
      setUpdatePath("hp-23d3");
    } else if (holidayPromoThreePath) {
      setUpdatePath("hp-3");
    } else if (duplicatePlanPagePath) {
      setUpdatePath("new-h");
    } else if (duplicatePlanPagePathABtest) {
      setUpdatePath("new-t");
    } else {
      setUpdatePath("new");
    }
  }, [
    holidayPromoPath,
    holidayOldPromoPath,
    holidayOldPromoPathDuplicateThree,
    holidayPromoThreePath,
    duplicatePlanPagePath,
    duplicatePlanPagePathABtest,
  ]);

  const initializeQuiz = useCallback(async () => {
    const savedQuizId = params.quizId || "";
    setQuizId(savedQuizId);

    if (quiz) {
      quiz.steps
        .filter(
          (x) => x.hasOwnProperty("image") || x.hasOwnProperty("thumbnail")
        )
        .forEach((x) => (new Image().src = x.image || x.thumbnail));

      const hash = await hashString(JSON.stringify(quiz.steps));
      const savedHash = localStorage.getItem(
        `MemoryOSQuestionsHash${savedQuizId}`
      );

      if (hash !== savedHash) {
        localStorage.setItem(`MemoryOSQuestionsHash${savedQuizId}`, hash);
        localStorage.removeItem(`MemoryOSQuizData${savedQuizId}`);
        localStorage.removeItem(`MemoryOSCurrentStep${savedQuizId}`);
      } else {
        const savedData =
          JSON.parse(localStorage.getItem(`MemoryOSQuizData${savedQuizId}`)) ||
          [];
        const savedStep =
          parseInt(localStorage.getItem(`MemoryOSCurrentStep${savedQuizId}`)) ||
          0;

        if (savedData.length === quiz.steps.length) {
          if (validHolidayPathsCheck) {
            navigate(preserveUrlParams(waitlist));
          } else if (duplicatePlanPagePath || duplicatePlanPagePathABtest) {
            navigate(preserveUrlParams(plan));
          } else {
            navigate(preserveUrlParams("/new/plan"));
          }
          return;
        }

        setUserData(savedData);
        setCurrentStep(savedStep);
        navigate(
          preserveUrlParams(
            `/${updatePath}/quiz${savedQuizId ? `/${savedQuizId}` : ""}/${
              savedStep + 1
            }`
          ),
          { replace: true }
        );
      }

      setSteps(quiz.steps);
    }
  }, [
    quiz,
    params.quizId,
    navigate,
    updatePath,
    validHolidayPathsCheck,
    duplicatePlanPagePath,
    duplicatePlanPagePathABtest,
    waitlist,
    plan,
  ]);

  useEffect(() => {
    initializeQuiz();
  }, [initializeQuiz]);

  const handleNextStep = useCallback(
    (data) => {
      const newUserData = [...userData, data];
      const nextStep = currentStep + 1;

      setUserData(newUserData);
      localStorage.setItem(
        `MemoryOSQuizData${quizId}`,
        JSON.stringify(newUserData)
      );
      localStorage.setItem(`MemoryOSCurrentStep${quizId}`, nextStep.toString());
      localStorage.setItem(
        `MemoryOSQuizLastUpdate${quizId}`,
        Date.now().toString()
      );

      if (nextStep < steps.length) {
        setCurrentStep(nextStep);

        navigate(
          preserveUrlParams(
            `/${updatePath}/quiz${quizId ? `/${quizId}` : ""}/${nextStep + 1}`
          ),
          { replace: true }
        );
      } else {
        // if (findUserData() !== null) {
        sendQuizRequest();
        // }

        if (validHolidayPathsCheck) {
          navigate(preserveUrlParams(waitlist));
        } else if (duplicatePlanPagePath || duplicatePlanPagePathABtest) {
          navigate(preserveUrlParams(plan));
        } else {
          navigate(preserveUrlParams("/new/plan"));
        }
      }

      analyticsEvent("pageView");
      window.scrollTo(0, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      userData,
      currentStep,
      steps.length,
      navigate,
      quizId,
      updatePath,
      validHolidayPathsCheck,
      duplicatePlanPagePath,
      duplicatePlanPagePathABtest,
    ]
  );

  const handleBackStep = useCallback(() => {
    const prevStep = currentStep - 1;
    const updatedUserData = userData.slice(0, -1);

    setUserData(updatedUserData);
    setCurrentStep(prevStep);

    localStorage.setItem(
      `MemoryOSQuizData${quizId}`,
      JSON.stringify(updatedUserData)
    );
    localStorage.setItem(`MemoryOSCurrentStep${quizId}`, prevStep.toString());
    localStorage.setItem(
      `MemoryOSQuizLastUpdate${quizId}`,
      Date.now().toString()
    );

    navigate(
      preserveUrlParams(
        `/${updatePath}/quiz${quizId ? `/${quizId}` : ""}/${prevStep + 1}`
      ),
      { replace: true }
    );
  }, [userData, currentStep, navigate, quizId, updatePath]);

  const stepComponents = {
    single: SingleStep,
    multiple: MultipleStep,
    static: StaticStep,
    rating: RatingStep,
    form: FormStep,
    email: EmailStep,
    summary: SummaryStep,
    plan: PlanStep,
    loader: LoaderStep,
    review: ReviewStep,
    trialStep: TrialStep,
    ageStep: AgeStep,
    gender: GenderStep,
    kickstarter: KickstarterStep,
    jonas: JonasStep,
  };

  const memoizedUserData = useMemo(() => userData, [userData]);

  // const CurrentStepComponent = stepComponents[steps[currentStep]?.type];

  const CurrentStepComponent = useMemo(
    () => stepComponents[steps[currentStep]?.type],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [steps, currentStep]
  );

  return (
    <Page>
      <Header
        style={{
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className={styles.topContainer}>
          <ButtonModule
            currentStep={currentStep}
            steps={steps}
            handleBackStep={handleBackStep}
          />
          <LogoModule />
        </div>

        <ProgressBarModule currentStep={currentStep} steps={steps} />
        {currentStep >= 2 &&
          steps[currentStep] &&
          steps[currentStep].showProgressBar && (
            <span className={styles.textPart}>
              {steps[currentStep]?.part || ""}
            </span>
          )}
      </Header>
      <Container
        style={{
          margin: "20px auto",
        }}
      >
        {CurrentStepComponent && (
          <CurrentStepComponent
            id={currentStep}
            step={steps[currentStep]}
            handleNextStep={handleNextStep}
            userData={memoizedUserData}
          />
        )}
      </Container>
    </Page>
  );
};

export default React.memo(NewQuiz);
