import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { useRouteCorrection } from "./context/RouteHandlerContext";
import { TimerProvider } from "./context/TimerContext";
import { useCampaignswell } from "./context/CampaignswellContext";

import { useGrowthbook } from "./context/GrowthBookContext";
import { useRemoteConfig } from "./context/RemoteConfigContext";
import { useEnvContext } from "./context/EnvironmentContext";
import { usePaymentForm } from "./context/PaymentFormContext";

import { useConsoleLog } from "./hooks/consoleLog";

import LandingD from "./pages/Landing_D/LandingD";
import LandingABtest from "./pages/LandingABtest/LandingABtest";

import NewQuiz from "./pages/NewQuiz";
import ABspecialOfferPlan from "./pages/Plan/ABspecialOfferPlan/ABspecialOfferPlan";
import RedesignThankYou from "./pages/ThankYou/RedesignThankYou/RedesignThankYou";

import Waitlist from "./pages/Waitlist/Waitlist";
import BlackFriday from "./pages/HolidayPromo/BlackFriday/BlackFriday";
import BlackFridayDiscount from "./pages/HolidayPromo/BlackFridayDiscount/BlackFridayDiscount";

import BlackFridayOld from "./pages/HolidayPromo/BlackFridayOld/BlackFridayOld";
import BlackFridayOldDiscount from "./pages/HolidayPromo/BlackFridayOldDiscount/BlackFridayOldDiscount";

import BlackFridayOldThree from "./pages/HolidayPromo/BlackFridayOldThree/BlackFridayOldThree";
import BlackFridayOldThreeDiscount from "./pages/HolidayPromo/BlackFridayOldThreeDiscount/BlackFridayOldThreeDiscount";

import HPThankYou from "./pages/ThankYou/HPThankYou/HPThankYou";

import DuplicateNewPlan from "./pages/Plan/DuplicateNewPlan/DuplicateNewPlan";
import PaymentFormPage from "./pages/PaymentFormPage/PaymentFormPage";
import DuplicateThankYou from "./pages/ThankYou/DuplicateThankYou/DuplicateThankYou";

import PlanPageNewTest from "./pages/Plan/PlanPageNewTest/PlanPageNewTest";
import DevNavBar from "./pages/dev_tools/DevNavBar/DevNavBar";
import ErrorPopup from "./pages/ErrorPopup/ErrorPopup";

import NewLogo from "./pages/templates/NewLogo/NewLogo";

function RedirectWithParams() {
  const logger = useConsoleLog();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search).toString();
  const newPath = searchParams ? `/new?${searchParams}` : "/new";

  // debug log
  useEffect(() => {
    logger.debug("Wrong path redirecting to:", newPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPath]);

  return <Navigate to={newPath} replace />;
}

function RouteHandler() {
  const navigate = useNavigate();
  const location = useLocation();
  const { correctRoute } = useRouteCorrection();

  useEffect(() => {
    const path = location.pathname;
    const correctedPath = correctRoute(path);

    if (correctedPath !== null && correctedPath !== path) {
      const searchParams = new URLSearchParams(location.search);
      const queryString = searchParams.toString();
      const newPath = queryString
        ? `${correctedPath}?${queryString}`
        : correctedPath;

      navigate(newPath, { replace: true });
    }
  }, [location, navigate, correctRoute]);

  return null;
}

function App() {
  const logger = useConsoleLog();

  const { triggerEvent, EVENT_TYPES } = useCampaignswell();
  const { abTestCampaignName } = useRemoteConfig();
  const { abValue } = useGrowthbook();
  const { isProduction } = useEnvContext();

  const { errPopupData } = usePaymentForm();

  useEffect(() => {
    if (!abValue || !abTestCampaignName) return;

    const handleEvent = async () => {
      try {
        await triggerEvent(EVENT_TYPES.CW_SEGMENT, {
          segment: abTestCampaignName,
          ab_test: abValue ?? "Fallback",
        });
      } catch (error) {
        logger.error("Failed to trigger CW_SEGMENT event:", error);
      }
    };

    handleEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abValue, abTestCampaignName]);

  return (
    <>
      <BrowserRouter
        history={createBrowserHistory({
          scrollRestoration: "auto",
        })}
      >
        <RouteHandler />

        {!isProduction && <DevNavBar />}
        {errPopupData.visible && (
          <ErrorPopup errorCode={errPopupData.errorCode} />
        )}

        <Routes>
          <Route path="/" element={<LandingD />} />

          {/* apply-discount flow  */}
          <Route path="/apply-discount" element={<ABspecialOfferPlan />} />
          <Route
            path="/apply-discount/payment"
            element={
              <TimerProvider>
                <PaymentFormPage />
              </TimerProvider>
            }
          />
          <Route
            path="/apply-discount/thankyou"
            element={<RedesignThankYou />}
          />

          {/* Default flow  */}
          {/* <Route path="/new" element={<LandingD />} /> */}
          <Route path="/new" element={<LandingABtest />} />

          <Route path="/new/quiz/:id" element={<NewQuiz />} />
          <Route path="/new/quiz/:quizId/:id" element={<NewQuiz />} />
          <Route path="/new/plan" element={<PlanPageNewTest />} />
          <Route
            path="/new/payment"
            element={
              <TimerProvider>
                <PaymentFormPage />
              </TimerProvider>
            }
          />
          <Route path="/new/thankyou" element={<RedesignThankYou />} />

          {/* Duplicate default flow A-B test payment form */}
          <Route path="/new-t" element={<LandingD />} />
          <Route path="/new-t/quiz/:id" element={<NewQuiz />} />
          <Route path="/new-t/quiz/:quizId/:id" element={<NewQuiz />} />
          <Route path="/new-t/plan" element={<PlanPageNewTest />} />
          <Route
            path="/new-t/payment"
            element={
              <TimerProvider>
                <PaymentFormPage />
              </TimerProvider>
            }
          />
          <Route path="/new-t/thankyou" element={<RedesignThankYou />} />

          {/* Duplicate default flow */}
          <Route path="/new-h" element={<LandingD />} />
          <Route path="/new-h/quiz/:id" element={<NewQuiz />} />
          <Route path="/new-h/quiz/:quizId/:id" element={<NewQuiz />} />
          <Route path="/new-h/plan" element={<DuplicateNewPlan />} />
          <Route
            path="/new-h/payment"
            element={
              <TimerProvider>
                <PaymentFormPage />
              </TimerProvider>
            }
          />
          <Route path="/new-h/thankyou" element={<DuplicateThankYou />} />

          {/* Black Friday flow  */}
          <Route path="/hp" element={<LandingD />} />
          <Route path="/hp/quiz/:id" element={<NewQuiz />} />
          <Route path="/hp/quiz/:quizId/:id" element={<NewQuiz />} />
          <Route path="/hp/waitlist" element={<Waitlist />} />
          <Route path="/hp/reserve" element={<BlackFriday />} />
          <Route
            path="/hp/reserve-essential"
            element={<BlackFridayDiscount />}
          />
          <Route path="/hp/thankyou" element={<HPThankYou />} />

          {/* Old Black Friday flow  */}
          <Route path="/hp-23" element={<LandingD />} />
          <Route path="/hp-23/quiz/:id" element={<NewQuiz />} />
          <Route path="/hp-23/quiz/:quizId/:id" element={<NewQuiz />} />
          <Route path="/hp-23/waitlist" element={<Waitlist />} />
          <Route path="/hp-23/reserve" element={<BlackFridayOld />} />
          <Route
            path="/hp-23/reserve-essential"
            element={<BlackFridayOldDiscount />}
          />
          <Route path="/hp-23/thankyou" element={<HPThankYou />} />

          {/* Old Black Friday flow duplicate 3 */}
          <Route path="/hp-23d3" element={<LandingD />} />
          <Route path="/hp-23d3/quiz/:id" element={<NewQuiz />} />
          <Route path="/hp-23d3/quiz/:quizId/:id" element={<NewQuiz />} />
          <Route path="/hp-23d3/waitlist" element={<Waitlist />} />
          <Route path="/hp-23d3/reserve" element={<BlackFridayOld />} />
          <Route
            path="/hp-23d3/reserve-essential"
            element={<BlackFridayOldDiscount />}
          />
          <Route path="/hp-23d3/thankyou" element={<HPThankYou />} />

          {/* Old Black Friday flow page 3 */}
          <Route path="/hp-3" element={<LandingD />} />
          <Route path="/hp-3/quiz/:id" element={<NewQuiz />} />
          <Route path="/hp-3/quiz/:quizId/:id" element={<NewQuiz />} />
          <Route path="/hp-3/waitlist" element={<Waitlist />} />
          <Route path="/hp-3/reserve" element={<BlackFridayOldThree />} />
          <Route
            path="/hp-3/reserve-essential"
            element={<BlackFridayOldThreeDiscount />}
          />
          <Route path="/hp-3/thankyou" element={<HPThankYou />} />

          {/* dev path */}
          <Route path="/dev/test/test_component" element={<NewLogo />} />

          <Route
            path="/.well-known/apple-developer-merchantid-domain-association.txt"
            component={() => {
              window.location.href =
                "/apple-developer-merchantid-domain-association.txt";
              return null;
            }}
          />

          <Route path="*" element={<RedirectWithParams />} />
        </Routes>
      </BrowserRouter>
      <div className="hidden">{`Build version: ${process.env.REACT_APP_VERSION}`}</div>
    </>
  );
}

export default App;
