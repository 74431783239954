import React from "react";

// import useUniqueComponentIds from "../../../../hooks/useUniqueComponentIds";

import { useAnalyticsUserData } from "../../../../context/AnalyticsUserData";
import { useCampaignswell } from "../../../../context/CampaignswellContext";

import styles from "./AgeStepStyle.module.css";

const AgeStep = React.memo(({ id, step, handleNextStep }) => {
  const { updUserData } = useAnalyticsUserData();
  const { triggerEvent, EVENT_TYPES } = useCampaignswell();

  // const ids = useUniqueComponentIds("ageStep", id, step);

  const handleAgeSelection = async (answer, index) => {
    try {
      updUserData("user_age", answer.text);
      handleNextStep({ id, answer, answerID: index, type: "ageStep" });

      await triggerEvent(EVENT_TYPES.CW_USER_AGE, {
        age: answer.analytics_value,
      });
    } catch (error) {
      console.error("Failed to process age selection:", error);
    }
  };

  return (
    <div className={styles.container}>
      {/* <span className={styles.title} id={ids.forElement("title")}> */}
      <span className={styles.title} id={step.title_id}>
        {step.title}
      </span>
      {/* <span className={styles.subTitle} id={ids.forElement("subTitle")}> */}
      <span className={styles.subTitle} id={step.sub_title_id}>
        {step.subTitle}
      </span>
      <div className={styles.answerButtonsContainer}>
        {step.answers.map((answer, index) => (
          <button
            key={`${id}-${index}`}
            onClick={() => handleAgeSelection(answer, index)}
            className={styles.answerButton}
          >
            <img src={answer.img} alt="" className={styles.buttonImg} />
            <div className={styles.textContainer}>
              <span className={styles.answerText}>{answer.text}</span>
              <img
                src="../../img/arrowNarrowRight.png"
                alt="arrow-right"
                className={styles.arrowRight}
              />
            </div>
          </button>
        ))}
      </div>
    </div>
  );
});

export default AgeStep;
