import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from "react";

import { useConsoleLog } from "../../hooks/consoleLog";
import { userDataReducer, initialState } from "./reducer";
import { USER_ACTIONS } from "./types";
import { useRemoteConfig } from "../RemoteConfigContext";

import Loader from "../../pages/templates/Loader/Loader";
import { useCurrentPath } from "../../hooks/useCurrentPath";

const UserDataContext = createContext();

export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error("useUserData() must be used within UserDataProvider");
  }
  return context;
};
const USER_DATA_STORAGE_KEY = "MemoryOSuState";

export const UserDataProvider = ({ children }) => {
  const logger = useConsoleLog();
  const [state, dispatch] = useReducer(userDataReducer, initialState);
  const [userStateIsLoading, setIsLoading] = useState(false);
  const [isCIDInitialized, setIsCIDInitialized] = useState(false);

  const { appData, isConfigReady } = useRemoteConfig();

  const fetchInitialCID = useCallback(async () => {
    if (!appData?.REACT_APP_API_ENDPOINT) {
      logger.critical("API_ENDPOINT is not available");
      setIsCIDInitialized(true);
      return;
    }

    try {
      logger.log("Making API request for CID...");
      const response = await fetch(`${appData.REACT_APP_API_ENDPOINT}/cid`);
      const data = await response.json();

      if (data.cid) {
        dispatch({ type: USER_ACTIONS.SET_CID, payload: data.cid });
        logger.info("Received new CID:", data.cid);
      } else {
        logger.critical("Received response but no CID in data");
      }
    } catch (error) {
      logger.critical("Error getting CID:", error);
    } finally {
      setIsCIDInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData?.REACT_APP_API_ENDPOINT]);

  const updateCIDWithEmail = useCallback(
    async (email) => {
      logger.info("Starting updateCIDWithEmail:", { email });

      if (!appData?.REACT_APP_API_ENDPOINT) {
        logger.critical("API_ENDPOINT is not available for email update");
        return;
      }

      setIsLoading(true);
      const currentCID = state.cid;

      if (!currentCID) {
        logger.critical("CID is missing when trying to update email");
        setIsLoading(false);
        return;
      }

      try {
        logger.info("Making API request to check CID", { currentCID });
        const response = await fetch(
          `${appData.REACT_APP_API_ENDPOINT}/cid/check`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cid: currentCID, email }),
          }
        );

        const data = await response.json();

        if (data.cid && data.cid !== currentCID) {
          dispatch({ type: USER_ACTIONS.SET_CID, payload: data.cid });
          logger.log("CID updated after email verification:", data.cid);
        } else {
          logger.log(
            "After verification, CID was found to be relevant:",
            data.cid
          );
        }
      } catch (error) {
        logger.critical("Error updating CID from email:", error);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appData?.REACT_APP_API_ENDPOINT, state.cid]
  );

  const getGoogleClientID = () => {
    return new Promise((resolve, reject) => {
      try {
        window.gtag("get", "G-QWT83PZ7FZ", "client_id", (clientId) => {
          logger.specialInfo("Google clientID:", clientId);
          dispatch({ type: USER_ACTIONS.SET_GCID, payload: clientId });
          resolve(clientId);
        });
      } catch (error) {
        logger.critical("Error get google client id:", error);
        reject(error);
      }
    });
  };

  useEffect(() => {
    const initializeCID = async () => {
      if (!isConfigReady) {
        logger.warnInfo("Config not ready yet, waiting...");
        return;
      }
      const storedCID = state.cid;

      if (storedCID) {
        dispatch({ type: USER_ACTIONS.SET_CID, payload: storedCID });
        setIsCIDInitialized(true);
        logger.specialInfo("CID initialized from localStorage", storedCID);
      } else {
        await fetchInitialCID();
      }
    };

    initializeCID();
    getGoogleClientID();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchInitialCID, isConfigReady, appData]);

  const setStateUserName = useCallback(
    async (name) => {
      try {
        if (!name) {
          return logger.critical("user name is missing");
        }
        dispatch({ type: USER_ACTIONS.SET_NAME, payload: name });
      } catch (error) {
        logger.critical("Error setting user name:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const setStateUserEmail = useCallback(
    async (email) => {
      logger.info("Setting user email:", email);
      return new Promise((resolve) => {
        dispatch({ type: USER_ACTIONS.SET_EMAIL, payload: email });
        setTimeout(() => {
          updateCIDWithEmail(email).then(resolve);
        }, 100);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateCIDWithEmail]
  );

  const [trigerUserAnalytics, setTrigerUserAnalytics] = useState(false);

  const trackUserAnalytics = useCallback(() => {
    const { cid, email, name, analytics_tracked } = state;

    if (cid && email && name) {
      const trackedState = analytics_tracked || {
        eventsTracked: false,
        lastTrackedValues: {
          cid: null,
          email: null,
          name: null,
        },
      };

      const valuesChanged =
        cid !== trackedState.lastTrackedValues.cid ||
        email !== trackedState.lastTrackedValues.email ||
        name !== trackedState.lastTrackedValues.name;

      if (!trackedState.eventsTracked || valuesChanged) {
        try {
          setTrigerUserAnalytics(true);

          const newTrackedState = {
            eventsTracked: true,
            lastTrackedValues: {
              cid,
              email,
              name,
            },
          };

          dispatch({
            type: USER_ACTIONS.SET_ANALYTICS_TRACKED,
            payload: newTrackedState,
          });

          logger.specialInfo(
            "Analytics events tracked successfully",
            newTrackedState
          );
        } catch (error) {
          logger.critical("Error tracking analytics events:", error);
        }
      } else {
        logger.warnInfo("Analytics Lead events already tracked for current user data");
      }
    }
  }, [state, dispatch, logger]);

  useEffect(() => {
    trackUserAnalytics();
  }, [state.cid, state.email, state.name, trackUserAnalytics]);

  const [linkLoadingStatus, setLinkLoadingStatus] = useState(false);

  const postUserLinkEmail = useCallback(
    async () => {
      if (!state.user_order_id || !state.social_profile_email) {
        logger.critical(
          "User order ID or user social provider email is missing"
        );
        return;
      }

      try {
        logger.info("Making API request to link email");

        const requestBody = {
          order_id: state.user_order_id,
          provider: state.social_provider.toUpperCase(),
          email: state.social_profile_email,
        };

        logger.info("Request body for link email:", requestBody);

        const response = await fetch(
          `${appData.REACT_APP_API_ENDPOINT}/order/link`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              order_id: state.user_order_id,
              provider: state.social_provider.toUpperCase(),
              email: state.social_profile_email,
            }),
          }
        );

        if (response.ok) {
          logger.info("Email linked successfully");
          dispatch({ type: USER_ACTIONS.SET_LINKED_EMAIL, payload: true });
        } else if (response.status === 400) {
          logger.warn("Email already linked for this provider");
        } else {
          logger.error("Failed to link email");
        }
      } catch (error) {
        logger.critical("Error linking email:", error);
        setLinkLoadingStatus(false);
      } finally {
        setLinkLoadingStatus(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appData?.REACT_APP_API_ENDPOINT, dispatch, state]
  );

  const checkUserLinkedEmail = useCallback(
    async (orderId) => {
      try {
        logger.info("Making API request for check email link");
        if (!orderId) {
          const error = new Error("checkUserLinkedEmail, order_id is missing");
          logger.critical(error.message);
          return;
        }

        logger.info("Request body for check email link:", orderId);

        const response = await fetch(
          `${appData.REACT_APP_API_ENDPOINT}/order/link/status`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              order_id: orderId,
            }),
          }
        );
        const data = await response.json();

        dispatch({ type: USER_ACTIONS.SET_LINKED_EMAIL, payload: data.linked });
        logger.debug(`linked status:`, data.linked);
        return data;
      } catch (error) {
        logger.critical("Error getting get email link status:", error);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appData?.REACT_APP_API_ENDPOINT, dispatch]
  );

  const [startEmailLink, setStartEmailLink] = useState(false);

  const initUserEmailLinking = useCallback(
    (provider, profile) => {
      logger.info(`Social provider`, provider);
      logger.info(`Social profile`, profile);
      try {
        if (!provider || !profile) {
          const error = new Error(
            "Social provider or social profile data is missing"
          );
          logger.critical(error.message);
          return;
        } else if (!profile.email) {
          dispatch({
            type: USER_ACTIONS.SET_SOCIAL_PROFILE_EMAIL,
            payload: null,
          });
          const error = new Error(
            "Social provider or social profile data is missing"
          );
          logger.critical(error.message);
          return;
        }
        setLinkLoadingStatus(true);

        dispatch({ type: USER_ACTIONS.SET_SOCIAL_PROVIDER, payload: provider });
        dispatch({
          type: USER_ACTIONS.SET_SOCIAL_PROFILE_EMAIL,
          payload: profile.email || null,
        });
        setStartEmailLink(true);
      } catch (error) {
        logger.critical("Error in initUserEmailLinking:", error);
      }
    },
    [logger, dispatch]
  );

  useEffect(() => {
    if (
      startEmailLink &&
      state.social_provider &&
      state.social_profile_email &&
      state.user_order_id
    ) {
      postUserLinkEmail();
      setStartEmailLink(false);
    }
  }, [
    startEmailLink,
    state.social_provider,
    state.social_profile_email,
    state.user_order_id,
    postUserLinkEmail,
  ]);

  const currentPath = useCurrentPath();

  const [hasCheckedEmail, setHasCheckedEmail] = useState(false);
  useEffect(() => {
    const thankyouPagePath = /^\/($|\?)|(\/thankyou)(\/|$|\?|#)/.test(
      currentPath
    );

    if (thankyouPagePath && !hasCheckedEmail && state.user_order_id) {
      logger.debug(`currentPath thankyou page`, thankyouPagePath);
      logger.debug(`Using order_id:`, state.user_order_id);
      checkUserLinkedEmail(state.user_order_id);
      setHasCheckedEmail(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, state.user_order_id, hasCheckedEmail]);

  const setUserOrderID = useCallback(
    (order_id) => {
      try {
        dispatch({ type: USER_ACTIONS.SET_USER_ORDER_ID, payload: order_id });
      } catch (error) {
        logger.critical("Error set user order_id:", error);
      } finally {
        logger.debug(`User order_id set:`, order_id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  useEffect(() => {
    const hasNonNullValues = Object.values(state).some(
      (value) => value !== null
    );

    if (hasNonNullValues) {
      const nonNullData = Object.fromEntries(
        Object.entries(state).filter(([_, value]) => value !== null)
      );

      localStorage.setItem(USER_DATA_STORAGE_KEY, JSON.stringify(nonNullData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      setStateUserEmail,
      setStateUserName,
      setUserOrderID,
      initUserEmailLinking,
      userStateIsLoading,
      linkLoadingStatus,
      trackUserAnalytics,
      trigerUserAnalytics,
      setTrigerUserAnalytics,
    }),
    [
      state,
      setStateUserEmail,
      setStateUserName,
      setUserOrderID,
      initUserEmailLinking,
      userStateIsLoading,
      linkLoadingStatus,
      trackUserAnalytics,
      trigerUserAnalytics,
      setTrigerUserAnalytics,
    ]
  );

  if (!isConfigReady || !isCIDInitialized) {
    return <Loader />;
  }

  return (
    <UserDataContext.Provider value={contextValue}>
      {userStateIsLoading && <Loader />}
      {children}
    </UserDataContext.Provider>
  );
};
