import React, { useEffect, useState } from "react";

import useUniqueComponentIds from "../../../../../hooks/useUniqueComponentIds";

import { QuizContextProvider } from "../../../context/QuizContext";

import Loader from "./Loader";
import StoreSlider from "./StoreSlider/StoreSlider";
import HeaderSlider from "./HeaderSlider/HeaderSlider";

import styles from "./QuizLoader.module.css";
import { Container } from "../../../../Plan/styles";

const QuizLoader = React.memo(({ data, setResLoader }) => {
  const ids = useUniqueComponentIds("QuizLoader", 1, data);

  const [headerSlideText, setHeaderSlideText] = useState(null);

  const [userQuizData] = useState(
    JSON.parse(localStorage.getItem("MemoryOSQuizData"))
  );

  const checkQuizAnswer = (data) => {
    if (data.answers && Array.isArray(data.answers)) {
      const selectedAnswers = data.answers
        .filter((answer) => answer.selected)
        .map((answer) => answer.copy)
        .join(", ");
      return selectedAnswers;
    } else if (data.answer && typeof data.answer === "string") {
      return data.answer;
    } else {
      console.log("Error quiz answer data");
      return "";
    }
  };

  useEffect(() => {
    if (data.header.slideStaticHeader === true) {
      setHeaderSlideText(data.header.slideStaticText);
    } else if (data.header.slideGoalsHeader === true) {
      setHeaderSlideText(
        data.header.slideGoalsText.map((item) => {
          if (typeof item === "string") {
            return item;
          } else if (typeof item === "object") {
            return `${item.title} ${checkQuizAnswer(userQuizData[item.step])}`;
          }
          return null;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Container
      style={{
        marginTop: "10px",
      }}
    >
      <QuizContextProvider>
        <Loader setResLoader={setResLoader} loaderInterval={data.loaderDelay} />
      </QuizContextProvider>
      <div className={styles.TextContainer}>
        {data.header.slideStaticHeader || data.header.slideGoalsHeader ? (
          headerSlideText && (
            <HeaderSlider
              slideData={headerSlideText}
              delayProps={data.header.delay}
            />
          )
        ) : (
          <div className={styles.HeaderTextContainer}>
            <span className={styles.TextHeader}>{data.header.staticText}</span>
          </div>
        )}
        <div className={styles.hrLine} />
        <div className={styles.TextMain}>
          <span
            className={styles.TextMainHeader}
            id={ids.forElement("textMain")}
          >
            {data.textMain}
          </span>
          <span
            className={styles.TextMainFooter}
            id={ids.forElement("textMainLow")}
          >
            {" "}
            {data.textMainLow}
          </span>
        </div>
        <StoreSlider dataReview={data.review} delayProps={data.reviewDelay} />
      </div>
    </Container>
  );
});

export default QuizLoader;
