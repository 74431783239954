import { useEnvContext } from "../context/EnvironmentContext";

const BASE_STYLES = {
  REGULAR: {
    fontSize: "12px",
  },
  BOLD: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  LARGE_BOLD: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  PADDING: {
    padding: "2px 4px",
  },
  LARGE_PADDING: {
    padding: "3px 6px",
  },
  ROUNDED: {
    borderRadius: "3px",
  },
};

const LOG_TYPES = {
  LOG: {
    name: "log",
    emoji: "📝",
    consoleMethod: "log",
    styles: {
      color: "#2E4057",
      ...BASE_STYLES.REGULAR,
    },
    showInProduction: false,
  },
  WARN: {
    name: "warn",
    emoji: "⚠️",
    consoleMethod: "warn",
    styles: {
      color: "#cf8704",
      backgroundColor: "#FFF3E0",
      ...BASE_STYLES.BOLD,
      ...BASE_STYLES.PADDING,
      ...BASE_STYLES.ROUNDED,
    },
    showInProduction: false,
  },
  INFO: {
    name: "info",
    emoji: "ℹ️",
    consoleMethod: "log",
    styles: {
      color: "#0066cc",
      backgroundColor: "#e6f3ff",
      ...BASE_STYLES.BOLD,
      ...BASE_STYLES.PADDING,
      ...BASE_STYLES.ROUNDED,
    },
    showInProduction: false,
  },
  INFO_WARN: {
    name: "warnInfo",
    emoji: "ℹ️",
    consoleMethod: "warn",
    styles: {
      color: "#b87802",
      backgroundColor: "#FFF3E0",
      ...BASE_STYLES.BOLD,
      ...BASE_STYLES.PADDING,
      ...BASE_STYLES.ROUNDED,
    },
    showInProduction: false,
  },
  SPECIAL_INFO: {
    name: "specialInfo",
    emoji: "🔍",
    consoleMethod: "log",
    styles: {
      color: "rgb(12,137,12)",
      backgroundColor: "#F0FFF0",
      border: "1px solid #32CD32",
      ...BASE_STYLES.BOLD,
      ...BASE_STYLES.PADDING,
      ...BASE_STYLES.ROUNDED,
    },
    showInProduction: false,
  },
  AB_TEST: {
    name: "abTest",
    emoji: "🎯",
    consoleMethod: "log",
    styles: {
      color: "#038f1a",
      backgroundColor: "#e6f3ff",
      ...BASE_STYLES.BOLD,
      ...BASE_STYLES.PADDING,
      ...BASE_STYLES.ROUNDED,
    },
    showInProduction: true,
  },
  EVENT: {
    name: "event",
    emoji: "",
    consoleMethod: "log",
    styles: {
      color: "#054741",
      backgroundColor: "inherit",
      ...BASE_STYLES.BOLD,
      ...BASE_STYLES.PADDING,
      ...BASE_STYLES.ROUNDED,
    },
    showInProduction: false,
    prefixGenerator: (provider) => `[EVENT:${provider}]`,
  },
  DEV_MODE: {
    name: "devMode",
    emoji: "🚧",
    consoleMethod: "log",
    styles: {
      color: "#D32F2F",
      backgroundColor: "#FFEBEE",
      ...BASE_STYLES.BOLD,
      ...BASE_STYLES.PADDING,
      ...BASE_STYLES.ROUNDED,
    },
    showInProduction: false,
  },
  ERROR: {
    name: "error",
    emoji: "❌",
    consoleMethod: "error",
    styles: {
      color: "#D32F2F",
      backgroundColor: "#FFEBEE",
      ...BASE_STYLES.BOLD,
      ...BASE_STYLES.PADDING,
      ...BASE_STYLES.ROUNDED,
    },
    showInProduction: true,
  },
  CRITICAL: {
    name: "critical",
    emoji: "🚨",
    consoleMethod: "error",
    styles: {
      color: "white",
      backgroundColor: "#D32F2F",
      ...BASE_STYLES.LARGE_BOLD,
      ...BASE_STYLES.LARGE_PADDING,
      ...BASE_STYLES.ROUNDED,
    },
    showInProduction: true,
    prefix: "[CRITICAL]",
  },
  DEBUG: {
    name: "debug",
    emoji: "🐛",
    consoleMethod: "log",
    styles: {
      color: "#7B1FA2",
      backgroundColor: "#F3E5F5",
      ...BASE_STYLES.BOLD,
      ...BASE_STYLES.PADDING,
      ...BASE_STYLES.ROUNDED,
    },
    showInProduction: false,
  },
};

const convertStylesToString = (styles) => {
  return Object.entries(styles)
    .map(
      ([key, value]) =>
        `${key.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)}: ${value};`
    )
    .join(" ");
};

export const useConsoleLog = () => {
  const { isProduction } = useEnvContext();

  const createLogger =
    (logType) =>
    (...args) => {
      const config = LOG_TYPES[logType];
      if (isProduction && !config.showInProduction) return;

      if (logType === "EVENT") {
        const prefix = config.prefixGenerator(args[0]);
        const restArgs = args.slice(1);

        console[config.consoleMethod](
          `%c${prefix}`,
          convertStylesToString(config.styles),
          ...restArgs
        );
        return;
      }

      const prefix = config.prefixGenerator
        ? [config.prefixGenerator(args[0])]
        : config.prefix
        ? [config.prefix]
        : [];

      const messageArgs = config.prefixGenerator ? args.slice(1) : args;
      const complexArgs = messageArgs.some(
        (arg) => typeof arg === "object" || Array.isArray(arg)
      );

      if (complexArgs) {
        const textArgs = messageArgs.filter(
          (arg) => typeof arg !== "object" && !Array.isArray(arg)
        );
        const complexValues = messageArgs.filter(
          (arg) => typeof arg === "object" || Array.isArray(arg)
        );
        const text = [
          ...prefix,
          ...(textArgs.length ? textArgs : ["Data:"]),
        ].join(" ");

        console[config.consoleMethod](
          `${config.emoji} %c${text}`,
          convertStylesToString(config.styles),
          ...complexValues
        );
      } else {
        console[config.consoleMethod](
          `${config.emoji} %c${[...prefix, ...messageArgs].join(" ")}`,
          convertStylesToString(config.styles)
        );
      }
    };

  return Object.keys(LOG_TYPES).reduce(
    (acc, type) => ({
      ...acc,
      [LOG_TYPES[type].name]: createLogger(type),
    }),
    {}
  );
};
