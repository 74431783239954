import React, { useCallback, useEffect, useState } from "react";
// import { usePageConfig } from "../../context/PageConfigContext";
import { useNavigate } from "react-router-dom";

import { preserveUrlParams } from "../../utils/urlParams";
import { useNavigationPath } from "../../hooks/navigationPath";

import { useEvent } from "../../context/EventContext";
import { useGrowthbook } from "../../context/GrowthBookContext";
import { useEnvContext } from "../../context/EnvironmentContext";
import { useDevMode } from "../../context/DevMode";

import NewLogo from "../templates/NewLogo/NewLogo";
import Loader from "../templates/Loader/Loader";

import styles from "./LandingABtest.module.css";

const LandingABtest = () => {
  // const { findDataComponent } = usePageConfig();
  const { isProduction } = useEnvContext();
  const { analyticsEvent } = useEvent();

  const { abValue } = useGrowthbook();
  const { devModeVariant } = useDevMode();


  const navigate = useNavigate();
  const navigatePath = useNavigationPath("quiz");

  // const [dataComponent, setDataComponent] = useState(null);
  const [progress, setProgress] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [loadingDelay, setLoadingDelay] = useState(null);

  const [renderText, setRenderText] = useState("");

  const textToRender = [
    "I'll guide you through the world's #1 memory app, designed to empower people like you with a skill of remembering what's important.",
    "Ready to start your memory transformation? Please hold while we load your survey."]

  useEffect(() => {
    if (isProduction) {
      setLoadingDelay(abValue === 1 ? 9 : 5)
      setRenderText(abValue === 1 ? textToRender[0] : textToRender[1])
    } else if (!isProduction) {
      setLoadingDelay(devModeVariant === 1 ? 9 : 5)
      setRenderText(devModeVariant === 1 ? textToRender[0] : textToRender[1])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abValue, devModeVariant, isProduction])


  const fireAnalytics = useCallback(() => {
    analyticsEvent("landingPageView");
  }, [analyticsEvent]);

  useEffect(() => {
    fireAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (imageLoaded) {
      setShowText(true);

      setTimeout(() => {
        setShowContent(true);
      }, 200);
    }
  }, [imageLoaded]);

  useEffect(() => {
    if (loadingDelay && imageLoaded) {
      const totalDuration = loadingDelay * 1000;
      const interval = 50;
      let currentProgress = 0;

      const timer = setInterval(() => {
        currentProgress += (interval / totalDuration) * 100;
        if (currentProgress >= 100) {
          clearInterval(timer);
          currentProgress = 100;

          setFadeOut(true);

          setTimeout(() => {
            analyticsEvent("landingPageComplete");
            analyticsEvent("getStartedClicked", { page: "LandingD" });
            navigate(preserveUrlParams(navigatePath));
          }, 700);
        }
        setProgress(currentProgress);
      }, interval);

      return () => clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageLoaded]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  if (!imageLoaded) {
    return (
      <div className={styles.container}>
        <Loader />
        <img
          src={"./img/landing_Mo.png"}
          alt="landingMO"
          style={{ display: "none" }}
          onLoad={handleImageLoad}
        />
      </div>
    );
  }

  const textClasses = `${styles.topText} ${showText ? (fadeOut ? styles.fadeOut : styles.fadeInText) : styles.hidden
    }`;

  const mainTextClasses = `${styles.mainText} ${showText ? (fadeOut ? styles.fadeOut : styles.fadeInText) : styles.hidden
    }`;

  const contentClasses = `${styles.landingImgMo} ${showContent
    ? fadeOut
      ? styles.fadeOut
      : styles.fadeInContent
    : styles.hidden
    }`;

  const loaderClasses = `${styles.loaderContainer} ${showContent
    ? fadeOut
      ? styles.fadeOut
      : styles.fadeInContent
    : styles.hidden
    }`;

  return (
    <div className={styles.container}>
      <NewLogo />
      <img
        src={"./img/landing_Mo.png"}
        alt="landingMO"
        className={contentClasses}
      />
      <span className={textClasses}>Hi, I’m Mo</span>
      <span className={mainTextClasses}>
        {renderText}
      </span>
      <div className={loaderClasses}>
        <div className={styles.textContainer}>
          <span className={styles.loadingText}>Loading onboarding survey</span>
          <span className={styles.loadingText}>{Math.round(progress)}%</span>
        </div>
        <div className={styles.progressContainer}>
          <div className={styles.progress} style={{ width: `${progress}%` }} />
        </div>
      </div>
    </div>
  );
};

export default LandingABtest;
