import { useMemo, useRef, useState, useEffect } from "react";

const useUniqueComponentIds = (prefix, stepId, step, additionalDeps = null) => {
  const [instanceId, setInstanceId] = useState(
    () =>
      `${Date.now().toString(36)}-${Math.random().toString(36).substring(2, 7)}`
  );

  const prevHashRef = useRef("");

  useEffect(() => {
    const createObjectHash = (obj) => {
      if (obj === null || obj === undefined) {
        return "null";
      }

      try {
        if (typeof obj !== "object") {
          return String(obj);
        }

        if (Array.isArray(obj)) {
          return obj.map((item) => createObjectHash(item)).join("|");
        }

        const sortedKeys = Object.keys(obj).sort();
        return sortedKeys
          .map((key) => `${key}:${createObjectHash(obj[key])}`)
          .join("|");
      } catch (e) {
        console.warn("Error creating hash for object:", e);
        return JSON.stringify(obj);
      }
    };

    const combinedData = { step, additionalDeps };

    const currentHash = createObjectHash(combinedData);

    if (currentHash !== prevHashRef.current) {
      prevHashRef.current = currentHash;
      setInstanceId(
        `${Date.now().toString(36)}-${Math.random()
          .toString(36)
          .substring(2, 7)}`
      );
    }
  }, [step, additionalDeps]);

  return useMemo(() => {
    const baseId = `${prefix}-${stepId}-${instanceId}`;
    const elementsIds = {};

    return {
      forElement: (elementType) => {
        if (!elementsIds[elementType]) {
          elementsIds[elementType] = `${baseId}-${elementType}`;
        }
        return elementsIds[elementType];
      },
    };
  }, [prefix, stepId, instanceId]);
};

export default useUniqueComponentIds;
