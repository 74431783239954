import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRemoteConfig } from "../../../context/RemoteConfigContext";
import { TimerProvider } from "../../../context/TimerContext";
import { useErrorHandler } from "../../../context/ErrorHandlerContext";
import { useEvent } from "../../../context/EventContext";
import { usePageContent } from "../../../context/PageContext";

import PageComponent from "../templates/PageComponent/PageComponent";
import ContainerComponent from "../templates/ContainerComponent/ContainerComponent";

import TopDiscount from "./TopDiscount/TopDiscount";
import TopDoubleComponent from "./TopDoubleComponent/TopDoubleComponent";
import ChoosePlan from "./ChoosePlan/ChoosePlan";
import PrizesContainer from "./PrizesContainer/PrizesContainer";
import WhatIsMemoryOS from "./WhatIsMemoryOS/WhatIsMemoryOS";
import WithinMemoryOS from "./WithinMemoryOS/WithinMemoryOS";
import YourGrandmaster from "./YourGrandmaster/YourGrandmaster";
import InfoImgBlock from "./InfoImgBlock/InfoImgBlock";
import MoreEfficiently from "./MoreEfficiently/MoreEfficiently";
import DidYouKnow from "./DidYouKnow/DidYouKnow";
import ReviewContainer from "./ReviewContainer/ReviewContainer";
import AnswerQuestions from "./AnswerQuestions/AnswerQuestions";
import SecondChoosePlan from "./ChoosePlanSecond/SecondChoosePlan";
import MoneyBackGuarantee from "./MoneyBackGuarantee/MoneyBackGuarantee";
import ViewingPageCounter from "./ViewingPageCounter/ViewingPageCounter";
import JoinTheRest from "./JoinTheRest/JoinTheRest";
import PleaseNote from "./PleaseNote/PleaseNote";
import FooterContainer from "./FooterContainer/FooterContainer";
import DownSalePopup from "./DownSalePopup/DownSalePopup";
import PaymentBlock from "../../PaymentBlock/PaymentBlock";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import SpecialOfferPopup from "./SpecialOfferPopup/SpecialOfferPopup";

const ABspecialOfferPlan = () => {
  const { showError } = useErrorHandler();
  const { componentsData } = useRemoteConfig();
  const { analyticsEvent } = useEvent();

  const {
    showHideDownSalePopup,
    showHideSpecialOfferPopup,
    productProcessing,
  } = usePageContent();

  const [visibleComponents, setVisibleComponents] = useState(new Set());
  const componentRefs = useRef({});
  const observerRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    analyticsEvent("planPageView");
    productProcessing(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "50px",
      threshold: 0.1,
    };

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const componentName = entry.target.dataset.component;
        if (entry.isIntersecting) {
          setVisibleComponents((prev) => new Set([...prev, componentName]));
        }
      });
    }, options);

    componentsData.forEach((item) => {
      if (item.showHide && componentRefs.current[item.component]) {
        observerRef.current.observe(componentRefs.current[item.component]);
      }
    });

    if (footerRef.current) {
      observerRef.current.observe(footerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [componentsData]);

  const getComponentByName = useMemo(
    () => (name) => {
      const components = {
        TopDiscount: () => <TopDiscount />,
        TopDoubleComponent: () => <TopDoubleComponent />,
        ChoosePlan: () => <ChoosePlan />,
        PrizesContainer: () => <PrizesContainer />,
        WhatIsMemoryOS: () => <WhatIsMemoryOS />,
        WithinMemoryOS: () => <WithinMemoryOS />,
        YourGrandmaster: () => <YourGrandmaster />,
        InfoImgBlock: () => <InfoImgBlock />,
        MoreEfficiently: () => <MoreEfficiently />,
        DidYouKnow: () => <DidYouKnow />,
        ReviewContainer: () => <ReviewContainer />,
        AnswerQuestions: () => <AnswerQuestions />,
        SecondChoosePlan: () => <SecondChoosePlan />,
        MoneyBackGuarantee: () => <MoneyBackGuarantee />,
        ViewingPageCounter: () => <ViewingPageCounter />,
        JoinTheRest: () => <JoinTheRest />,
        PleaseNote: () => <PleaseNote />,
      };

      return components[name] ? components[name]() : null;
    },
    []
  );

  const backgroundColor = "none";
  const styleOpt = { width: "100%" };

  return (
    <TimerProvider>
      <PageComponent bgColor={backgroundColor}>
        <ContainerComponent styleOption={styleOpt}>
          {showError ? <ErrorHandler /> : null}
          {componentsData.map(
            (item) =>
              item.showHide && (
                <div
                  key={item.component}
                  ref={(el) => (componentRefs.current[item.component] = el)}
                  data-component={item.component}
                >
                  {visibleComponents.has(item.component) &&
                    getComponentByName(item.component)}
                </div>
              )
          )}
          <PaymentBlock />
          {showHideDownSalePopup && <DownSalePopup />}
          {showHideSpecialOfferPopup && <SpecialOfferPopup />}
        </ContainerComponent>
        <div ref={footerRef} data-component="footer">
          {visibleComponents.has("footer") && <FooterContainer />}
        </div>
      </PageComponent>
    </TimerProvider>
  );
};

export default ABspecialOfferPlan;
