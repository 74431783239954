import React from "react";

import { useUserData } from "../../../../../context/UserData/UserDataContext";

import AuthButtons from "./AuthButtons/AuthButtons";
import LinkedBlock from "./LinkedBlock/LinkedBlock";

import Loader from "../../../../templates/Loader/Loader";
import Loading from "../../../../templates/Loading/Loading";

import styles from "./StepOneStyle.module.css";

const StepOne = ({ dataComponent }) => {
  const { state, linkLoadingStatus } = useUserData();

  if (!state) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <p className={styles.stepNumber}>{dataComponent.number}</p>
      {!state.email_linked && (
        <p className={styles.stepHeader}>{dataComponent.headerText}</p>
      )}

      <img
        src="/img/frameStepOneTy.png"
        alt="img-step-one"
        className={styles.stepImg}
      />
      {linkLoadingStatus ? (
        <Loading />
      ) : state.email_linked === false ? (
        <AuthButtons
          dataComponent={dataComponent.authButtons}
          enableFacebook={dataComponent.enableFacebook}
          enableAppleLogin={dataComponent.enableAppleLogin}
          enableGoogleLogin={dataComponent.enableGoogleLogin}
        />
      ) : (
        <LinkedBlock data={dataComponent.linkedText} />
      )}
    </div>
  );
};

export default StepOne;
