import React, { createContext, useContext, useEffect, useState } from "react";

import { useConsoleLog } from "../hooks/consoleLog";

import { useRemoteConfig } from "./RemoteConfigContext";
import { useUrlContext } from "./urlContext";

import Loader from "../pages/templates/Loader/Loader";
// import { useImagePreloader } from "./ImagePreloaderContext";

const PageConfigContext = createContext();

export const usePageConfig = () => useContext(PageConfigContext);

export const PageConfigProvider = ({ children }) => {
  const logger = useConsoleLog();

  const { appData, quizData, componentsData } = useRemoteConfig();
  const { queryParams, getCurrentPath } = useUrlContext();
  // const { prepareQuizData } = useImagePreloader();

  const [currentQuiz, setCurrentQuiz] = useState();

  useEffect(() => {
    if (quizData.length !== 0) {
      // const filteredSteps = quizData.quizD.steps.filter(
      //   (step) => step.showHide === true
      // );
      // const filteredQuizData = {
      //   ...quizData.quizD,
      //   steps: filteredSteps,
      // };

      const filteredSteps = quizData.steps.filter(
        (step) => step.showHide === true
      );
      const filteredQuizData = {
        ...quizData,
        steps: filteredSteps,
      };

      // const preparedQuizData = prepareQuizData(filteredQuizData);

      // logger.warn(`preparedQuizData`, preparedQuizData);

      // setCurrentQuiz(preparedQuizData);
      setCurrentQuiz(filteredQuizData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizData]);

  const findDataComponent = (componentName) => {
    return componentsData.find(
      (component) => component.component === componentName
    );
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  const removeUndefinedOrNullFields = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== undefined && obj[key] !== null) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const sendQuizRequest = async () => {
    logger.log(`start sendQuizRequest`);

    try {
      const userData = JSON.parse(localStorage.getItem("MemoryOSUserData"));
      if (!userData)
        throw new Error("User data not found, quiz request failed.");

      const quizId = "";
      const getCachedData = (key) => JSON.parse(localStorage.getItem(key));

      const checkSendData = {
        questionsHash: localStorage.getItem("MemoryOSQuestionsHash"),
        origin: appData.REACT_APP_ORIGIN,
        lastUpdate: getCachedData(`MemoryOSQuizLastUpdate${quizId}`),
        source: queryParams.utm_source || queryParams.source,
        placement:
          queryParams.utm_medium || queryParams.medium || queryParams.placement,
        campaign: queryParams.utm_campaign || queryParams.campaign,
        content: queryParams.utm_content || queryParams.content,
        pageUrl: await getCurrentPath(),
      };

      const payload = {
        system: removeUndefinedOrNullFields(checkSendData),
        user: {
          email: userData.userEmail,
          name: userData.userName,
          fbc: getCookie("_fbc"),
          fbp: getCookie("_fbp"),
          snapUuidC1: getCookie("_scid"),
          snapClickId: queryParams.ScCid,
        },
        answers: getCachedData(`MemoryOSQuizData${quizId}`),
        questions: currentQuiz.steps,
      };

      const response = await fetch(
        `${appData.REACT_APP_API_ENDPOINT}/quiz/submit`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json;charset=UTF-8" },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) throw new Error(`HTTP error! status:`, response.status);
      const data = await response.json();
      logger.info(`quiz response data:`, data.system);
    } catch (error) {
      logger.critical("Transaction error:", error.message);
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [showOrNotModal, setShowOrNotModal] = useState(false);
  const [showModalActSubsc, setShowModalActSubsc] = useState(false);

  if (!componentsData) {
    return <Loader />;
  }

  const contextValue = {
    findDataComponent,
    currentQuiz,
    sendQuizRequest,
    showModal,
    setShowModal,
    showOrNotModal,
    setShowOrNotModal,
    showModalActSubsc,
    setShowModalActSubsc,
  };

  return (
    // <PageConfigContext.Provider value={contextValue}>
    // {children}
    // </PageConfigContext.Provider>
    <PageConfigContext.Provider value={contextValue}>
      {children}
    </PageConfigContext.Provider>
  );
};
