import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Payment from "@solidgate/react-sdk";

import { useErrorHandler } from "../../../../../context/ErrorHandlerContext";
import { useRemoteConfig } from "../../../../../context/RemoteConfigContext";
import { useEvent } from "../../../../../context/EventContext";
import { usePaymentBlock } from "../../../../../context/PaymentBlockContext";
import { usePageConfig } from "../../../../../context/PageConfigContext";
import { usePaymentForm } from "../../../../../context/PaymentFormContext";
import { usePageContent } from "../../../../../context/PageContext";

import { preserveUrlParams } from "../../../../../utils/urlParams";
import { useNavigationPath } from "../../../../../hooks/navigationPath";

import PlanDetails from "./PlanDetails/PlanDetails";
import PaymentSystems from "./PaymentSystems/PaymentSystems";
import EmailBlock from "../../../../PaymentFormPage/EmailBlock/EmailBlock";

import styles from "./PaymentFormStyle.module.css";
import Loader from "../../../../templates/Loader/Loader";
import PaySafeSecure from "./PaySafeSecure/PaySafeSecure";

const PaymentForm = () => {
  const { setErrorHandler } = useErrorHandler();
  const { appData, paymentButtons } = useRemoteConfig();
  const { analyticsEvent } = useEvent();
  const { abTestVariant } = useRemoteConfig();
  const { findDataComponent } = usePageConfig();
  const { setStartPaymentForm, setOrderLocalData } = usePaymentBlock();
  const { downSaleStatus, setShowHideDownSalePopup, activateDiscount } =
    usePageContent();
  const {
    currentOrder,
    merchantDataSet,
    navigateObserver,
    scriptUrl,
    startOrderStatusCheck,
    prepareOrder,
    isLoading,
  } = usePaymentForm();

  const data = findDataComponent("PaymentForm");

  const paypalBtnRef = useRef(null);
  const appleContainerRef = useRef(null);
  const googleContainerRef = useRef(null);
  const navigate = useNavigate();
  const navigatePath = useNavigationPath("thankyou");

  const [loading, setLoading] = useState(true);

  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    const prepareOrderAsync = async () => {
      if (currentOrder !== undefined) {
        const updatedSelOption = {
          ...currentOrder,
          varOpt: abTestVariant,
        };
        setOrderLocalData(updatedSelOption);
        await prepareOrder();
        setLoading(false);
      }
    };

    prepareOrderAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scriptUrl && paypalBtnRef.current) {
      paypalBtnRef.current.addEventListener(
        "order-started-processing",
        function (e) {
          console.log("order-started-processing", e);
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "order-processed",
        function (e) {
          console.log("order-processed", e);
          startOrderStatusCheck();
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "order-already-processed",
        function (e) {
          console.log("order-already-processed", e);
          startOrderStatusCheck();
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "button-ready",
        function (e) {
          console.log("button-ready", e);
          setFormLoading(false);
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "button-error",
        function (e) {
          console.log("button-error", e);
          setErrorHandler(e);
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptUrl]);

  const handleOnSuccess = (e) => {
    console.log("handleOnSuccess: ", { e });
    analyticsEvent("addPaymentInfo");

    if (paypalBtnRef.current) {
      paypalBtnRef.current.style.display = "none";
    }
    startOrderStatusCheck();
  };

  const googlePayButtonParams = {
    enabled: paymentButtons.google_pay,
    color: "black",
  };
  const applePayButtonParams = {
    enabled: paymentButtons.apple_pay,
    // integrationType: "js",
    color: "black",
    type: "plain",
  };

  const customFormStyles = {
    template: "flat",
    submit_button: {
      "background-color": "#02BEB2;",
    },
  };
  const formParams = {
    formTypeClass: "flat",
    submitButtonText: "Continue",
  };

  useEffect(() => {
    if (navigateObserver) {
      navigate(preserveUrlParams(navigatePath));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateObserver, navigate]);

  const closePaymentForm = () => {
    setStartPaymentForm(false);
    // if (timer > 0) {
    if (downSaleStatus !== true) {
      if (activateDiscount) {
        setShowHideDownSalePopup(true);
      }
    }
    // }
  };

  // const handleApplePayClick = () => {
  //   setTimeout(() => {
  //     if (appleContainerRef.current) {
  //       const applePayButton =
  //         appleContainerRef.current.querySelector("apple-pay-button");
  //       if (applePayButton) {
  //         applePayButton.click();
  //       } else {
  //         appleContainerRef.current.click();
  //       }
  //     }
  //   }, 100);
  // };

  const [payWithCardDisplay, setPayWithCardDisplay] = useState("none");

  const payWithCard = () => {
    if (payWithCardDisplay === "flex") {
      setPayWithCardDisplay("none");
    } else if (payWithCardDisplay === "none") {
      setPayWithCardDisplay("flex");
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles.ShadowPopup}>
      {isLoading && <Loader />}
      {formLoading ? <Loader /> : null}
      <div className={styles.ScrollContainer}>
        <div className={styles.NewPaymentform}>
          <div className={styles.OrderDetailsContainer}>
            <span className={styles.OrderDetails}>{data.headerText}</span>
            <button
              className={styles.closeOrderButton}
              onClick={() => {
                closePaymentForm();
              }}
            >
              <img
                src="../img/paymentFormCloseButton.png"
                alt="paymentFormCloseButton"
                className={styles.paymentFormCloseButton}
              />
            </button>
          </div>
          <PlanDetails />
          <span className={styles.payFastText}>Pay fast & secure with</span>
          {/* enable this code new apple pay button */}
          {/* <div className={styles.applePayButtonContainer}>
            <div
              className={styles.applePayCopyButton}
              onClick={handleApplePayClick}
            >
              <img
                src="../img/apple-white-logo.png"
                alt="apple pay"
                className={styles.applePayLogo}
              />
              <span className={styles.applePayText}>Pay</span>
            </div>
            <div className={styles.appleContainerBtn} ref={appleContainerRef} />
          </div> */}
          {/* end enable */}

          {/* disable this code */}
          <div className={styles.appleContainerBtn} ref={appleContainerRef} />
          {/* end */}
          <div className={styles.googleContainerBtn} ref={googleContainerRef} />
          <div className={styles.googlePayPalContainer}>
            {parseInt(appData.REACT_APP_IS_PAYPAL_ORDER) && scriptUrl ? (
              <div
                id="paypal-button"
                ref={paypalBtnRef}
                style={{ marginTop: "10px" }}
              />
            ) : null}
          </div>

          <button
            className={styles.payWithCardButton}
            onClick={payWithCard}
            style={{
              border:
                payWithCardDisplay === "none" ? "none" : "3px solid #076AFF",
            }}
          >
            <span className={styles.payWithCardText}>Pay with card</span>
            <img
              className={styles.payWithCardImg}
              src="../img/FramePayWithCardReserve.png"
              alt="FramePayWithCardReserve"
            />
            <img
              className={styles.payWithCardImg}
              src="../img/FramePayWithCardReserve2.png"
              alt="FramePayWithCardReserve2"
            />
          </button>

          {/* <div className={styles.textOrPayWithCard}>
            <span>{data.payWithCardText}</span>
          </div> */}
          <div
            className={styles.CardPayContainer}
            style={{ display: payWithCardDisplay }}
          >
            <span className={styles.cardDetailsText}>
              Enter your card details:
            </span>
            <Payment
              merchantData={merchantDataSet}
              googlePayButtonParams={googlePayButtonParams}
              applePayButtonParams={applePayButtonParams}
              googlePayContainerRef={googleContainerRef}
              applePayContainerRef={appleContainerRef}
              styles={customFormStyles}
              formParams={formParams}
              onSuccess={handleOnSuccess}
            />
          </div>
          <PaySafeSecure />
          <EmailBlock />
          <div className={styles.hrLine} />
          <PaymentSystems />
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
