import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CryptoJS from "crypto-js";
import * as amplitude from "@amplitude/analytics-browser";

import { useRemoteConfig } from "./RemoteConfigContext";
import { useEnvContext } from "./EnvironmentContext";
import { useEventFilter } from "./EventFilter";
import { useConsoleLog } from "../hooks/consoleLog";
import { useDevMode } from "./DevMode";
import { useUserData } from "./UserData/UserDataContext";

const EventContext = createContext();

export const useEvent = () => useContext(EventContext);

export const EventProvider = ({ children }) => {
  const { snaptrEventStatus } = useEventFilter();
  const { stagingEventsStatus } = useDevMode();
  const { trigerUserAnalytics, setTrigerUserAnalytics } = useUserData();

  const {
    appData,
    analyticsFirebase,
    fbEvent,
    apiGeoUrl,
    emailsToDisableAnalyticsEvent,
  } = useRemoteConfig();

  const { isProduction } = useEnvContext();

  const logger = useConsoleLog();

  const [userData, setSnaptrUserData] = useState(
    JSON.parse(localStorage.getItem("MemoryOSUserData"))
  );

  const [userGeoData, setUserGeoData] = useState(
    JSON.parse(localStorage.getItem("MemoryOSGeoData"))
  );

  const userIdentifyEvent = async (eventName, eventProperty) => {
    if (!isProduction && !stagingEventsStatus) {
      if (!isAnalyticsAllowed) {
        logger.warn("Amplitude events are currently blocked");
        return;
      }
    } else if (isProduction) {
      if (!isAnalyticsAllowed) {
        logger.warn("Amplitude events are currently blocked");
        return;
      }
    }

    const identifyEvent = new amplitude.Identify();

    logger.event(
      ` Amplitude userIdentifyEvent`,
      `property: ${eventName}, value: ${eventProperty}`
    );

    identifyEvent.set(eventName.toString(), eventProperty.toString());

    amplitude.identify(identifyEvent);
  };

  const hashData = (data) => {
    const lowercaseData = data.toLowerCase();
    const hashedData = CryptoJS.SHA256(lowercaseData).toString();
    return hashedData;
  };

  const getEmailFromLocalStorage = (emailsToDisableAnalyticsEvent) => {
    const userData = JSON.parse(localStorage.getItem("MemoryOSUserData"));
    const quizData = JSON.parse(localStorage.getItem("MemoryOSQuizData"));

    const findEmailInQuizData = (data) => {
      const formItem = data.find(
        (item) =>
          item.type === "form" && item.formValues && item.formValues.Email
      );
      return formItem ? formItem.formValues.Email : null;
    };

    const checkEmailInDisableList = (email) => {
      return !emailsToDisableAnalyticsEvent.includes(email.toLowerCase());
    };

    if (userData && userData.userEmail) {
      return checkEmailInDisableList(userData.userEmail) ? true : false;
    }

    if (quizData) {
      const quizEmail = findEmailInQuizData(quizData);
      if (quizEmail) {
        return checkEmailInDisableList(quizEmail) ? true : false;
      }
    }

    return true;
  };

  const [isAnalyticsAllowed, setIsAnalyticsAllowed] = useState(null);

  const checkAnalyticsConditions = useCallback(() => {
    return (
      isProduction &&
      appData.REACT_APP_MARKETING_TELEMETRY !== 0 &&
      getEmailFromLocalStorage(emailsToDisableAnalyticsEvent)
    );
  }, [
    isProduction,
    appData.REACT_APP_MARKETING_TELEMETRY,
    emailsToDisableAnalyticsEvent,
  ]);

  useEffect(() => {
    const updateAnalyticsAllowed = () => {
      const allowed = checkAnalyticsConditions();
      setIsAnalyticsAllowed(allowed);
    };
    updateAnalyticsAllowed();

    const interval = setInterval(updateAnalyticsAllowed, 60000);
    return () => clearInterval(interval);
  }, [checkAnalyticsConditions]);

  const cleanUrl = (url) => {
    return url.split("?")[0];
  };

  const path = cleanUrl(window.location.href);

  const getAnalyticsParams = () => {
    const { email, name, cid } =
      JSON.parse(localStorage.getItem("MemoryOSuState")) || {};

    const leadParams = { email, name };
    const completeRegistrationParams = { email, name, cid };
    return { leadParams, completeRegistrationParams };
  };

  const analyticsEvent = useCallback(
    (eventName, eventParams) => {
      if (!isProduction && !stagingEventsStatus) {
        if (!isAnalyticsAllowed) {
          logger.warn("Analytics events are currently blocked");
          return;
        }
      } else if (isProduction) {
        if (!isAnalyticsAllowed) {
          logger.warn("Analytics events are currently blocked");
          return;
        }
      }

      if (appData.REACT_APP_MARKETING_TELEMETRY === 0) {
        logger.warn(`Marketing events tracking disabled`);
        return;
      }

      if (eventParams) {
        logger.event(` ${eventName} `, `Params:`, eventParams);
      } else {
        logger.event(` ${eventName}`);
      }

      switch (eventName) {
        case "landingPageView":
          if (window.fbq !== null) {
            window.fbq("trackCustom", "landingPageView");
          }

          if (window.ttq !== null) {
            window.ttq.track("Landing Page View");
            logger.event(" TT event", "Landing Page View");
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("landing_page_view", {
              event_name: "landing_page_view",
              event_category: "landing_page",
              page_url: path,
            });
          }

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "PAGE_VIEW");
          }

          break;

        case "landingPageComplete":
          if (window.ttq !== null) {
            window.ttq.track("Landing Page Complete");
            logger.event("TT event", "Landing Page Complete");
          }
          break;

        case "getStartedClicked":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("click", {
              event_category: "click",
              event_label: "get_started_clicked",
            });
          }

          if (window.fbq !== null) window.fbq("track", "AddToWishlist");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_TO_WISHLIST");
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("get_started_clicked", {
              event_category: "click",
              event_name: "get_started_clicked",
            });
          }

          break;

        case "pageView":
          if (window.fbq !== null) {
            window.fbq("trackCustom", "page_view");
            window.fbq("track", "ViewContent");
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("page_view", {
              event_name: "page_view",
            });
          }

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "PAGE_VIEW");
          }

          break;

        case "quizStart":
          if (window.ttq !== null) {
            window.ttq.track("Quiz Start");
            logger.event("TT event", "Quiz Start");
          }
          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("quiz_start", {
              event_category: "quiz_start",
              event_name: "quiz_start",
              page_url: path,
            });
          }
          break;

        case "quizPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("quiz_page_view", {
              event_category: "quiz_page_view",
              event_label: "quiz_page_view",
              quiz_number: eventParams,
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("quiz_page_view", {
              event_category: "quiz_page_view",
              event_name: "quiz_page_view",
              quiz_number: eventParams,
            });
          }

          if (window.fbq !== null) {
            window.fbq("trackCustom", "quizPageView");
            window.fbq("track", "ViewContent");
          }

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "PAGE_VIEW");
          }

          break;

        case "leadFormSubmit":
          const { leadParams } = getAnalyticsParams();

          if (leadParams) {
            if (analyticsFirebase !== null)
              analyticsFirebase.logEvent("lead_form_submit", leadParams);

            if (
              typeof amplitude !== "undefined" &&
              typeof amplitude.track === "function"
            ) {
              amplitude.track("lead_form_submit", {
                ...leadParams,
                event_category: "lead",
              });
            }
            const hashedLeadParams = { ...leadParams };
            hashedLeadParams.email = hashData(hashedLeadParams.email);
            
            if (window.fbq !== null) {
              window.fbq("track", "Lead", { leadEventData: hashedLeadParams });
              window.fbq("trackCustom", "ViewContentContact");
            }

            if (window.snaptr !== null && snaptrEventStatus === true) {
              window.snaptr("track", "CUSTOM_EVENT_2");
            }
            logger.event("leadFormSubmit params", leadParams);
            logger.event(`leadFormSubmit hash params`, hashedLeadParams);
          } else {
            logger.error(`Lead form submit params not found`);
          }

          break;

        case "nameFormSubmit":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("name_form_submit", {
              event_category: "first_name",
              event_label: eventParams,
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("name_form_submit", {
              event_category: "first_name",
              ...eventParams,
            });
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentContact");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "CUSTOM_EVENT_1");
          }

          break;

        case "completeRegistration":
          const { completeRegistrationParams } = getAnalyticsParams();

          if (completeRegistrationParams) {
            const { email, name, cid } = completeRegistrationParams;

            if (
              analyticsFirebase &&
              typeof analyticsFirebase.setUserId === "function"
            ) {
              analyticsFirebase.setUserId(cid);
              logger.event(`analyticsFirebase userId set for:`, cid);
            } else {
              logger.error(
                `analyticsFirebase or setUserId method not available`
              );
            }

            if (typeof amplitude !== "undefined") {
              amplitude.setUserId(cid);
              logger.event(`amplitude user id set for:`, cid);

              userIdentifyEvent("user_email", email);
              userIdentifyEvent("user_name", name);
            }

            const hashedCompleteRegistrationParams = {
              email: hashData(email),
              name: hashData(name),
              cid: hashData(cid),
            };

            if (window.ttq !== null) {
              window.ttq.identify({
                email: hashedCompleteRegistrationParams.email,
                name: hashedCompleteRegistrationParams.name,
                external_id: hashedCompleteRegistrationParams.cid,
              });
              window.ttq.track("Complete Registration");
              logger.event(
                `TT Complete Registration`,
                `params: `,
                hashedCompleteRegistrationParams
              );
            }
          } else {
            logger.error(`Complete registration params not found`);
          }

          break;

        case "conversion":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("conversion", {
              send_to: "AW-666333408/I4bYCIa4_MMBEODh3b0C",
            });
          }

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "CUSTOM_EVENT_3");
          }

          break;

        case "planPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("plan_page_view", {
              event_category: "plan_page_view",
              event_label: "plan_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("plan_page_view", {
              event_category: "plan_page_view",
              event_label: "plan_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("Plan Page View");
            logger.event("TT event", `Plan Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentPlanPage");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "duplicatePlanPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("duplicate_plan_page_view", {
              event_category: "duplicate_plan_page_view",
              event_label: "duplicate_plan_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("duplicate_plan_page_view", {
              event_category: "duplicate_plan_page_view",
              event_label: "duplicate_plan_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("Duplicate Plan Page View");
            logger.event("TT event", `Duplicate Plan Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentDuplicatePlanPage");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "waitlistPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("waitlist_page_view", {
              event_category: "waitlist_page_view",
              event_label: "waitlist_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("waitlist_page_view", {
              event_category: "waitlist_page_view",
              event_label: "waitlist_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("Waitlist Page View");
            logger.event("TT event", `Waitlist Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentWaitlist");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hpExpertPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp_expert_page_view", {
              event_category: "hp_expert_page_view",
              event_label: "hp_expert_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp_expert_page_view", {
              event_category: "hp_expert_page_view",
              event_label: "hp_expert_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP Expert Page View");
            logger.event("TT event", `HP Expert Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHPexpert");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hpEssentialPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp_essential_page_view", {
              event_category: "hp_essential_page_view",
              event_label: "hp_essential_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp_essential_page_view", {
              event_category: "hp_essential_page_view",
              event_label: "hp_essential_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP Essential Page View");
            logger.event("TT event", `HP Essential Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHPessential");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hp23ExpertPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp23_expert_page_view", {
              event_category: "hp23_expert_page_view",
              event_label: "hp23_expert_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp23_expert_page_view", {
              event_category: "hp23_expert_page_view",
              event_label: "hp23_expert_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP 23 Expert Page View");
            logger.event(`TT event`, `HP 23 Expert Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHP23expert");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hp23EssentialPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp23_essential_page_view", {
              event_category: "hp23_essential_page_view",
              event_label: "hp23_essential_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp23_essential_page_view", {
              event_category: "hp23_essential_page_view",
              event_label: "hp23_essential_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP 23 Essential Page View");
            logger.event(`TT event`, `HP 23 Essential Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHP23essential");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hp23dExpertPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp23d_expert_page_view", {
              event_category: "hp23d_expert_page_view",
              event_label: "hp23d_expert_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp23d_expert_page_view", {
              event_category: "hp23d_expert_page_view",
              event_label: "hp23d_expert_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP 23d Expert Page View");
            logger.event(`TT event`, `HP 23d Expert Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHP23dExpert");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hp23dEssentialPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp23d_essential_page_view", {
              event_category: "hp23d_essential_page_view",
              event_label: "hp23d_essential_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp23d_essential_page_view", {
              event_category: "hp23d_essential_page_view",
              event_label: "hp23d_essential_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP 23d Essential Page View");
            logger.event(`TT event`, `HP 23d Essential Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHP23dEssential");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hp23d2ExpertPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp23d2_expert_page_view", {
              event_category: "hp23d2_expert_page_view",
              event_label: "hp23d2_expert_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp23d2_expert_page_view", {
              event_category: "hp23d2_expert_page_view",
              event_label: "hp23d2_expert_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP 23d2 Expert Page View");
            logger.event(`TT event`, `HP 23d2 Expert Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHP23d2Expert");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hp23d2EssentialPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp23d2_essential_page_view", {
              event_category: "hp23d2_essential_page_view",
              event_label: "hp23d2_essential_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp23d2_essential_page_view", {
              event_category: "hp23d2_essential_page_view",
              event_label: "hp23d2_essential_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP 23d2 Essential Page View");
            logger.event(`TT event`, `HP 23d2 Essential Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHP23d2Essential");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hp23d3ExpertPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp23d3_expert_page_view", {
              event_category: "hp23d3_expert_page_view",
              event_label: "hp23d3_expert_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp23d3_expert_page_view", {
              event_category: "hp23d3_expert_page_view",
              event_label: "hp23d3_expert_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP 23d3 Expert Page View");
            logger.event(`TT event`, `HP 23d3 Expert Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHP23d3Expert");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hp23d3EssentialPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp23d3_essential_page_view", {
              event_category: "hp23d3_essential_page_view",
              event_label: "hp23d3_essential_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp23d3_essential_page_view", {
              event_category: "hp23d3_essential_page_view",
              event_label: "hp23d3_essential_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP 23d3 Essential Page View");
            logger.event(`TT event`, `HP 23d3 Essential Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHP23d3Essential");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hp3ExpertPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp3_expert_page_view", {
              event_category: "hp3_expert_page_view",
              event_label: "hp3_expert_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp3_expert_page_view", {
              event_category: "hp3_expert_page_view",
              event_label: "hp3_expert_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP 3 Expert Page View");
            logger.event(`TT event`, `HP 3 Expert Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHP3Expert");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "hp3EssentialPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("hp3_essential_page_view", {
              event_category: "hp3_essential_page_view",
              event_label: "hp3_essential_page_view",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("hp3_essential_page_view", {
              event_category: "hp3_essential_page_view",
              event_label: "hp3_essential_page_view",
              page_url: path,
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("HP 3 Essential Page View");
            logger.event(`TT event`, `HP 3 Essential Page View`);
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentHP3Essential");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_CART");
          }

          break;

        case "initiateCheckout":
          const currentOrderData = JSON.parse(
            localStorage.getItem("MemoryOSCurrentOrder")
          );

          if (window.ttq !== null) {
            const initialProductData = {
              value: currentOrderData.full_price,
              currency: "USD",
              contents: [
                {
                  content_id: currentOrderData.product_id,
                  content_name: currentOrderData.plan_name,
                  price: currentOrderData.full_price,
                },
              ],
              content_type: "product",
            };

            window.ttq.track("InitiateCheckout", initialProductData);
            logger.event(`TT event`, "Initiate Checkout", initialProductData);
          }
          break;

        case "beginCheckout":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("begin_checkout", {
              event_category: "begin_checkout",
              event_label: "begin_checkout",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("begin_checkout", {
              event_category: "begin_checkout",
              event_label: "begin_checkout",
            });
          }

          if (window.fbq !== null) {
            window.fbq("track", "InitiateCheckout");
          }

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "START_CHECKOUT");
          }

          if (window.ttq !== null) {
            const placeAnOrderData = JSON.parse(
              localStorage.getItem("MemoryOSCurrentOrder")
            );

            const initialPlaceAnOrderData = {
              value: placeAnOrderData.full_price,
              currency: "USD",
              contents: [
                {
                  content_id: placeAnOrderData.product_id,
                  content_type: "product",
                  description: placeAnOrderData.plan_name,
                  price: placeAnOrderData.full_price,
                  quantity: 1,
                },
              ],
              content_type: "product",
            };

            window.ttq.track("PlaceAnOrder", initialPlaceAnOrderData);

            logger.event(`TT event`, "Place An Order", initialPlaceAnOrderData);
          }

          break;

        case "addPaymentInfo":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("add_payment_info", {
              event_category: "add_payment_info",
              event_label: "add_payment_info",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("add_payment_info", {
              event_category: "add_payment_info",
              event_label: "add_payment_info",
            });
          }

          if (window.fbq !== null) window.fbq("trackCustom", "addPaymentInfo");

          if (window.snaptr !== null && snaptrEventStatus === true) {
            window.snaptr("track", "ADD_BILLING");
          }

          break;

        case "purchase":
          if (analyticsFirebase !== null)
            analyticsFirebase.logEvent("purchase", eventParams);

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("purchase", { ...eventParams, page_url: path });
          }

          if (window.fbq !== null && fbEvent) {
            const metaParams = {
              value: eventParams.value.toString(),
              currency: eventParams.currency,
              eventID: eventParams.event_id,
            };
            window.fbq("track", "Purchase", metaParams);
          }

          if (window.snaptr !== null && snaptrEventStatus === true) {
            const snapParams = {
              description: eventParams.description,
              item_category: "Subscription",
              price: eventParams.value,
              currency: "USD",
              number_items: 1,
              success: 1,
              client_dedup_id: eventParams.event_id,
              transaction_id: eventParams.transaction_id,
            };
            console.warn(`Purchase snap-params: ${snapParams}`);

            window.snaptr("track", "PURCHASE", snapParams);
          }

          if (window.ttq !== null) {
            const userData = JSON.parse(
              localStorage.getItem("MemoryOSUserData")
            );

            const initialCompletePaymentData = {
              value: eventParams.value,
              currency: "USD",
              user: {
                email: hashData(userData.userEmail),
              },
              contents: [
                {
                  content_id: eventParams.product_id,
                  description: eventParams.description,
                  quantity: 1,
                  price: eventParams.value,
                },
              ],
              content_type: "product",
              status: "fulfilled",
            };
            window.ttq.track("CompletePayment", initialCompletePaymentData, {
              event_id: eventParams.event_id,
            });
            logger.event(
              `TT event`,
              "Complete Payment",
              initialCompletePaymentData
            );
          }

          break;

        default:
          console.error(`Analytics event not handled: ${eventName}`);
          break;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAnalyticsAllowed, analyticsFirebase, fbEvent, stagingEventsStatus]
  );

  useEffect(() => {
    if (!isAnalyticsAllowed) {
      logger.warn("Analytics events are currently blocked");
      return;
    }
  
    if (!isProduction && !stagingEventsStatus) {
      return;
    }

    if (trigerUserAnalytics) {
      analyticsEvent("completeRegistration");
      analyticsEvent("leadFormSubmit");
      setTrigerUserAnalytics(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isProduction,
    stagingEventsStatus,
    isAnalyticsAllowed,
    trigerUserAnalytics,
  ]);

  const initSnaptr = async () => {
    if (!isProduction && !stagingEventsStatus) {
      if (!isAnalyticsAllowed) {
        console.warn(`Init Snaptr are currently blocked`);
        return;
      }
    } else if (isProduction) {
      if (!isAnalyticsAllowed) {
        logger.warn("Init Snaptr are currently blocked");
        return;
      }
    }

    if (userGeoData === null) {
      console.log("fetch geo data");
      try {
        const response = await fetch(apiGeoUrl);
        if (response.ok) {
          const geoData = await response.json();
          localStorage.setItem("MemoryOSGeoData", JSON.stringify(geoData));
          setUserGeoData(geoData);
        } else {
          console.error("Error response geo:", response.status);
        }
      } catch (error) {
        console.error("Error fetch geo:", error);
      }
    } else if (userGeoData !== null) {
      const hashedEmail =
        userData?.userEmail !== undefined
          ? await shaEmailHash(userData.userEmail)
          : null;

      // console.warn(`Snaptr user data:`, userData);
      // console.warn(`Snaptr geo data:`, userGeoData);

      const snaptrInitData = {
        user_email: userData?.userEmail ?? null,
        user_hashed_email: hashedEmail,
        firstname: userData?.userName ?? null,
        ip_address: userGeoData.ip,
        geo_city: userGeoData.city,
        geo_region: userGeoData.state,
        geo_postal_code: userGeoData.zip,
        geo_country: userGeoData.country,
      };
      // console.warn(`Snaptr init data:`, snaptrInitData);

      const cleanedSnaptrData = removeUndefOrNullFields(snaptrInitData);
      if (userData === null) {
        console.log("Processing init snaptr event: send only geo data");
      } else {
        console.log("Processing init snaptr event: send geo + user data");
      }
      window.snaptr(
        "init",
        "828579a5-bbbe-4d06-bc6a-53cc466efb08",
        cleanedSnaptrData
      );
    }
  };
  useEffect(() => {
    if (isAnalyticsAllowed !== null && snaptrEventStatus === true) {
      initSnaptr();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userGeoData, isAnalyticsAllowed]);

  const removeUndefOrNullFields = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== undefined && obj[key] !== null) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const shaEmailHash = async (email) => {
    const msgBuffer = new TextEncoder().encode(email.toLowerCase());
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    return hashHex;
  };

  const contextValue = {
    analyticsEvent,
    setSnaptrUserData,
    userIdentifyEvent,
  };

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  );
};
