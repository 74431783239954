import React from "react";

// import useUniqueComponentIds from "../../../../hooks/useUniqueComponentIds";

import { useAnalyticsUserData } from "../../../../context/AnalyticsUserData";
import { useCampaignswell } from "../../../../context/CampaignswellContext";

import { Step, Column, Title, Answers, Answer } from "../../styles";

const GenderStep = React.memo(({ id, step, handleNextStep }) => {
  const { updUserData } = useAnalyticsUserData();
  const { triggerEvent, EVENT_TYPES } = useCampaignswell();

  // const ids = useUniqueComponentIds("GenderStep", id, step);

  const handleGenderSelection = async (answer, index) => {
    try {
      updUserData("user_gender", answer.gender);

      handleNextStep({ id, answer, answerID: index, type: "gender" });

      await triggerEvent(EVENT_TYPES.CW_USER_GENDER, {
        gender: answer.analytics_value,
      });
    } catch (error) {
      console.error("Failed to process age selection:", error);
    }
  };
  return (
    <Step>
      <Column>
        {/* <Title id={ids.forElement("title")}>{step.title}</Title> */}
        <Title id={step.title_id}>{step.title}</Title>
      </Column>
      <Column>
        <Answers $hideOnMobile={step.subtype && step.subtype === "rate"}>
          {step.newAnswers.map((answer, index) => (
            <Answer
              key={`${id}-${index}`}
              onClick={() => handleGenderSelection(answer, index)}
            >
              {answer.gender}
            </Answer>
          ))}
        </Answers>
      </Column>
    </Step>
  );
});

export default GenderStep;
