import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { preserveUrlParams } from "../../../utils/urlParams";

import { useNavigationPath } from "../../../hooks/navigationPath";

import { usePaymentForm } from "../../../context/PaymentFormContext";
import { usePageConfig } from "../../../context/PageConfigContext";
import { useUrlContext } from "../../../context/urlContext";

import LogoHP from "../../templates/LogoHP/LogoHP";

import styles from "./PaymentErrorModalStyle.module.css";
import { useCurrentPath } from "../../../hooks/useCurrentPath";

const PaymentErrorModal = ({ errorCode }) => {
  const navigate = useNavigate();
  const previosPath = useNavigationPath("plan");
  const previosApplyDiscountPath = useNavigationPath("applyDiscount");

  const storageData = localStorage.getItem("MemoryOSuState");
  const { cid = null } = storageData ? JSON.parse(storageData) : {};

  console.log(cid);


  const currentPath = useCurrentPath();
  const pathOnly = new URL(currentPath).pathname;

  const shortenPath = (path) => {
    const parts = path.split("/").filter((part) => part !== "");
    const uniqueParts = [...new Set(parts)];
    return "/" + uniqueParts.join("/");
  };
  const shortApplyDiscountPath = shortenPath(previosApplyDiscountPath);

  const { setErrPopupData } = usePaymentForm();
  const { findDataComponent } = usePageConfig();
  const { applyDiscountPage, validHolidayPathsCheck } = useUrlContext();

  const data = findDataComponent("PaymentErrorModal");

  const closePopup = () => {
    setErrPopupData({ visible: false });
    if (!validHolidayPathsCheck && applyDiscountPage) {
      navigate(preserveUrlParams(shortApplyDiscountPath));
    } else if (!validHolidayPathsCheck) {
      navigate(preserveUrlParams(previosPath));
    }
  };

  const getErrorString = (error) => {
    if (error === null || error === undefined) {
      return "No error information available";
    }

    if (typeof error === "string") {
      return error;
    }

    if (typeof error === "object") {
      if (error.message) {
        return error.message;
      }

      try {
        return JSON.stringify(error);
      } catch (e) {
        return "Error object cannot be stringified";
      }
    }

    return String(error);
  };

  const handleContactSupport = () => {
    const subject = data.mail_subject;
    const errorString = getErrorString(errorCode);
    const body = `${data.mail_text[0]}\n\n${data.mail_text[1]}${cid}\n${data.mail_text[2]}${pathOnly}\n\nError Code: ${errorString}`;
    const mailtoLink = `mailto:support@memoryos.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink, "_blank");

    closePopup();
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <LogoHP />
        <div className={styles.modalText}>{data.text}</div>

        <button
          className={styles.continueButton}
          onClick={() => handleContactSupport()}
        >
          {data.button_text}
        </button>
      </div>
    </div>
  );
};

export default PaymentErrorModal;
