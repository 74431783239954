export const WebViewDetector = {
  isRunningInWebView() {
    const userAgent = navigator.userAgent.toLowerCase();
    
    const webViewIdentifiers = [
      'wv', 
      'fban', 
      'fbav', 
      'instagram', 
      'line', 
      'miuibrowser', 
      'kakaotalk',
      'naver',
      'twitterwebview' 
    ];
    
    const containsWebViewIdentifier = webViewIdentifiers.some(identifier => 
      userAgent.includes(identifier)
    );
    
    const isIOSWebView = 
      /(iphone|ipod|ipad).*applewebkit(?!.*safari)/i.test(userAgent) || 
      (window.webkit && window.webkit.messageHandlers && !userAgent.includes('crios') && !userAgent.includes('chrome'));
    
    const isKnownBrowser = 
      /chrome|firefox|edge|opera|safari/i.test(userAgent) && 
      !containsWebViewIdentifier && 
      !isIOSWebView;
      
    if (isKnownBrowser) {
      return false;
    }
    
    return containsWebViewIdentifier || isIOSWebView;
  }
}
  
//   hasFullServiceWorkerSupport() {
//     if (!('serviceWorker' in navigator)) {
//       return false;
//     }
    
//     const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
//     if (isIOS) {
//       const match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
//       if (match) {
//         const version = parseInt(match[1], 10);
//         if (version < 14) {
//           return false;
//         }
//       }
//     }
    
//     return true;
//   },
  
//   canUseServiceWorker() {
//     if (this.isRunningInWebView()) {
//       console.warn('Running in WebView - service worker features may be limited');
//     }
    
//     return this.hasFullServiceWorkerSupport();
//   }
// };

// export const ServiceWorkerManager = {
//   initialize() {
//     if (!WebViewDetector.canUseServiceWorker()) {
//       console.log('Service Worker functionality disabled due to WebView or browser compatibility issues');
//       return false;
//     }
    
//     if ('serviceWorker' in navigator) {
//       window.addEventListener('load', () => {
//         setTimeout(() => {
//           navigator.serviceWorker
//             .register('/service-worker.js', { scope: '/' })
//             .then((registration) => {
//               console.log(
//                 'Service worker successfully registered:',
//                 registration.scope
//               );

//               if (registration.active) {
//                 console.log('Service worker active');
//               }

//               registration.addEventListener('updatefound', () => {
//                 console.log('Service worker update found');
//                 const newWorker = registration.installing;

//                 newWorker.addEventListener('statechange', () => {
//                   console.log(
//                     'The service worker state has been changed to:',
//                     newWorker.state
//                   );
//                 });
//               });
//             })
//             .catch((error) => {
//               console.error('Service worker registration error:', error);
//             });
//         }, 500);
//       });
//       return true;
//     }
//     return false;
//   },
  
//   async unregisterAll() {
//     if ('serviceWorker' in navigator) {
//       try {
//         const registrations = await navigator.serviceWorker.getRegistrations();
//         const results = await Promise.all(
//           registrations.map(registration => registration.unregister())
//         );
//         console.log('Service workers unregistered:', results);
//         return results.some(result => result === true);
//       } catch (error) {
//         console.error('Error unregistering service workers:', error);
//         return false;
//       }
//     }
//     return Promise.resolve(false);
//   }
// };
